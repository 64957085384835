import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Joyride, { CallBackProps, STATUS, Step, Styles } from 'react-joyride';
import { useToast } from '@/components/ui/use-toast';
import { supabase } from '@/lib/supabase';

const steps: Step[] = [
  {
    target: '.joyride-welcome',
    content: "Welcome to EmailBounce! Let's get you started with email validation in just a few steps.",
    placement: 'center',
    disableBeacon: true,
    floaterProps: {
      disableAnimation: true,
    },
  },
  {
    target: '.validate-nav',
    content: 'Start by validating your email list. Simply drag and drop your file here or click to browse. We support CSV, TXT, and Excel files.',
    placement: 'right',
    disableBeacon: true,
  },
  {
    target: '.credits-nav',
    content: 'Your available credits are displayed here. Each email validation costs 1 credit. Need more? Click here to purchase credits at any time.',
    placement: 'right',
    disableBeacon: true,
  },
  {
    target: '.assistant-nav',
    content: "Use our AI-powered email assistant to write and improve your email content.",
    placement: 'right',
    disableBeacon: true,
  },
  {
    target: '.email-marketing-nav',
    content: 'Connect your favorite email marketing platforms like Mailchimp, SendGrid, or HubSpot for seamless list management.',
    placement: 'right',
    disableBeacon: true,
  },
  {
    target: '.settings-nav',
    content: "Customize your validation settings, manage API keys, and configure your preferences here.",
    placement: 'right',
    disableBeacon: true,
  }
];

const STEP_ROUTES = {
  0: '/dashboard',
  1: '/dashboard',
  2: '/dashboard',
  3: '/dashboard',
  4: '/dashboard',
  5: '/dashboard'
} as const;

const tourStyles: Partial<Styles> = {
  options: {
    arrowColor: '#ffffff',
    backgroundColor: '#ffffff',
    overlayColor: 'rgba(0, 0, 0, 0.75)',
    primaryColor: '#2563eb',
    textColor: '#020617',
    zIndex: 1000
  },
  buttonNext: {
    backgroundColor: '#2563eb',
    borderRadius: '0.5rem',
    padding: '0.625rem 1.25rem',
    fontSize: '0.95rem',
    fontWeight: '600',
    transition: 'all 0.2s'
  },
  buttonBack: {
    marginRight: '12px',
    color: '#2563eb',
    fontSize: '0.95rem',
    fontWeight: '600',
    padding: '0.625rem 1rem'
  },
  buttonSkip: {
    color: '#6b7280',
    fontSize: '0.95rem',
    fontWeight: '500'
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.75)'
  },
  spotlight: {
    backgroundColor: 'transparent'
  },
  tooltip: {
    backgroundColor: '#ffffff',
    borderRadius: '0.75rem',
    color: '#020617',
    fontSize: '0.95rem',
    padding: '1.25rem',
    boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
    maxWidth: '350px'
  },
  tooltipContainer: {
    textAlign: 'left' as const
  },
  tooltipContent: {
    padding: '0.5rem 0'
  },
  tooltipTitle: {
    fontSize: '1rem',
    fontWeight: '600',
    marginBottom: '0.75rem'
  }
};

export function OnboardingTour() {
  const [run, setRun] = useState(false);
  const { toast } = useToast();
  const location = useLocation();
  const navigate = useNavigate();
  const [stepIndex, setStepIndex] = useState(0);
  const [isNewUser, setIsNewUser] = useState(false);

  // Check if user is new and handle onboarding state
  useEffect(() => {
    const checkUserStatus = async () => {
      try {
        const { data: { session } } = await supabase.auth.getSession();
        if (!session) return;

        // Get user profile and registration date
        const { data: profile } = await supabase
          .from('user_profiles')
          .select('created_at, completed_onboarding, onboarding_step')
          .eq('id', session.user.id)
          .single();

        console.log('Profile:', profile); // Debug log

        if (profile && !profile.completed_onboarding) {
          console.log('Starting tour, pathname:', location.pathname); // Debug log
          setIsNewUser(true);
          
          // If we're not on the dashboard, navigate there
          if (location.pathname !== '/dashboard') {
            navigate('/dashboard');
            return;
          }

          // Start the tour
          setStepIndex(profile.onboarding_step || 0);
          setRun(true);
        }
      } catch (error) {
        console.error('Error checking user status:', error);
      }
    };

    // Run check on mount and when pathname changes
    checkUserStatus();
  }, [location.pathname, navigate]);

  // Save step progress
  const saveProgress = async (index: number) => {
    try {
      const { data: { session } } = await supabase.auth.getSession();
      if (!session) return;

      await supabase
        .from('user_profiles')
        .update({ 
          onboarding_step: index,
          updated_at: new Date().toISOString()
        })
        .eq('id', session.user.id);
    } catch (error) {
      console.error('Error saving progress:', error);
    }
  };

  // Handle tour completion and user profile update
  const handleTourComplete = async () => {
    try {
      const { data: { session } } = await supabase.auth.getSession();
      if (!session) return;

      // Update user profile to mark onboarding as completed
      const { error: updateError } = await supabase
        .from('user_profiles')
        .update({ 
          completed_onboarding: true,
          updated_at: new Date().toISOString()
        })
        .eq('id', session.user.id);

      if (updateError) throw updateError;

      // Clean up tour state
      setRun(false);

      // Show completion message
      toast({
        title: "Welcome to EmailBounce! ",
        description: "You're all set to start validating emails. Let's get started!",
      });

      // Navigate to dashboard
      setTimeout(() => {
        navigate('/dashboard', { replace: true });
      }, 1000);
    } catch (error) {
      console.error('Error completing tour:', error);
      toast({
        title: "Error",
        description: "Failed to complete the tour. Please try again.",
        variant: "destructive",
      });
    }
  };

  const handleNavigation = async (index: number) => {
    const targetRoute = STEP_ROUTES[index as keyof typeof STEP_ROUTES];
    if (targetRoute && location.pathname !== targetRoute) {
      console.log('Navigating to:', targetRoute, 'for step:', index);
      await navigate(targetRoute);
      setStepIndex(index);
    }
    // Always set run to true after navigation or if we're already on the right page
    setRun(true);
  };

  const handleJoyrideCallback = async (data: CallBackProps) => {
    const { status, type, index, action } = data;
    console.log('Joyride callback:', { type, index, action, status });

    // Handle manual close or skip
    if (action === 'reset' || action === 'close') {
      setRun(false);
      return;
    }

    // Handle step navigation
    if (type === 'step:before') {
      setRun(false); // Pause the tour during navigation
      await handleNavigation(index);
    }

    // Handle step completion
    if (type === 'step:after') {
      const nextIndex = index + 1;
      setStepIndex(nextIndex);
      await saveProgress(nextIndex);
      
      // Navigate to next step's route
      setRun(false); // Pause the tour during navigation
      await handleNavigation(nextIndex);
    }

    // Handle tour completion
    if (([STATUS.FINISHED, STATUS.SKIPPED] as string[]).includes(status)) {
      await handleTourComplete();
    }
  };

  return (
    <>
      <div className="joyride-welcome fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" style={{ pointerEvents: 'none' }} />
      <Joyride
        steps={steps}
        run={run}
        continuous
        showProgress
        showSkipButton
        spotlightPadding={4}
        disableOverlayClose
        hideCloseButton
        stepIndex={stepIndex}
        styles={tourStyles}
        floaterProps={{
          hideArrow: false,
          disableAnimation: true,
          offset: 10
        }}
        callback={handleJoyrideCallback}
        locale={{
          last: "Finish",
          skip: "Skip tour",
          next: "Next",
          back: "Back"
        }}
      />
    </>
  );
}
