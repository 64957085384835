import { useState } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { cn } from '@/lib/utils';
import { Button } from '@/components/ui/button';
import {
  LayoutDashboard,
  History,
  CreditCard,
  Settings,
  ChevronLeft,
  ChevronRight,
  Upload,
  Code,
  Mail,
  MessageSquareMore, 
  LucideIcon,
  Workflow,
  MailCheckIcon,
  TicketIcon
} from 'lucide-react';

const menuItems = [
  { icon: LayoutDashboard, label: 'Dashboard', path: '/dashboard', className: 'dashboard-nav' },
  {
    icon: Upload,
    label: 'Validate Emails',
    path: '/validate',
    className: 'validate-nav',
    subItems: [
      { label: 'Start Validation', path: '/validate' },
      { label: 'Validation History', path: '/history' }
    ]
  },
  {
    icon: MessageSquareMore,
    label: 'Email Assistant',
    path: '/email-assistant',
    className: 'assistant-nav',
    subItems: [
      { label: 'Write Emails', path: '/email-assistant' },
      { label: 'History', path: '/email-history' }
    ]
  },
  { icon: MailCheckIcon, label: 'Email Marketing', path: '/email-marketing', className: 'email-marketing-nav' },
  { icon: CreditCard, label: 'Credits', path: '/credits', className: 'credits-nav' },
  { icon: History, label: 'Payment History', path: '/payment-history', className: 'payment-nav' },
  { icon: Code, label: 'API Integration', path: '/api', className: 'api-nav' },
  { icon: Settings, label: 'Settings', path: '/settings', className: 'settings-nav' },
  { icon: TicketIcon, label: 'Support', path: '/support/tickets', className: 'support-nav' },
];

interface SidebarProps {
  isOpen: boolean;
  onClose: () => void;
}

interface NavItemProps {
  to: string;
  icon?: LucideIcon;
  children: React.ReactNode;
  onClick?: () => void;
  subItems?: { label: string; path: string; }[];
  collapsed?: boolean;
  className?: string;
}

function NavItem({ to, icon: Icon, children, onClick, subItems, collapsed, className }: NavItemProps) {
  const location = useLocation();
  // Set initial state to true for specific menu items
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(
    to === '/validate' || to === '/email-assistant'
  );
  const isActive = location.pathname === to || (subItems?.some(item => location.pathname === item.path));
  const hasSubItems = subItems && subItems.length > 0;

  const handleClick = (e: React.MouseEvent) => {
    if (hasSubItems) {
      e.preventDefault();
      setIsSubMenuOpen(!isSubMenuOpen);
    }
    // Call onClick if either:
    // 1. There are no subitems, or
    // 2. The click was directly on the parent element (not a child)
    if (!hasSubItems || e.target === e.currentTarget) {
      onClick?.();
    }
  };

  return (
    <div>
      <div
        onClick={handleClick}
        className={cn(
          'flex items-center gap-3 rounded-lg px-3 py-2 transition-all cursor-pointer',
          'text-muted-foreground hover:bg-accent hover:text-accent-foreground',
          isActive && 'bg-accent text-accent-foreground',
          className
        )}
      >
        <Link
          to={to}
          className="flex items-center flex-1"
          onClick={(e) => {
            e.stopPropagation();
            if (!hasSubItems) {
              onClick?.();
            }
          }}
        >
          {Icon && <Icon className="h-4 w-4" />}
          {!collapsed && <span className="ml-2">{children}</span>}
        </Link>
        {hasSubItems && !collapsed && (
          <ChevronRight className={cn('h-4 w-4 transition-transform', isSubMenuOpen && 'rotate-90')} />
        )}
      </div>
      {hasSubItems && isSubMenuOpen && !collapsed && (
        <div className="ml-6 mt-1 space-y-1">
          {subItems.map((item) => (
            <Link
              key={item.path}
              to={item.path}
              className={cn(
                'block py-1.5 px-3 text-sm rounded-lg transition-all',
                'text-muted-foreground hover:bg-accent hover:text-accent-foreground',
                location.pathname === item.path && 'bg-accent text-accent-foreground'
              )}
              onClick={onClick}
            >
              {item.label}
            </Link>
          ))}
        </div>
      )}
    </div>
  );
}

export function Sidebar({ isOpen, onClose }: SidebarProps) {
  const [collapsed, setCollapsed] = useState(false);

  return (
    <div
      className={cn(
        'fixed inset-y-0 left-0 z-40 flex w-64 flex-col border-r',
        'bg-background transition-transform duration-200 ease-in-out',
        !isOpen && '-translate-x-full md:translate-x-0',
        collapsed && 'w-16'
      )}
    >
      <div className="flex h-14 items-center justify-end px-4">
        <Button
          variant="ghost"
          size="icon"
          onClick={() => setCollapsed(!collapsed)}
          className="hidden md:flex"
        >
          {collapsed ? <ChevronRight className="h-4 w-4" /> : <ChevronLeft className="h-4 w-4" />}
        </Button>
      </div>

      <nav className="flex-1 space-y-1 px-2 mt-10">
        {menuItems.map((item) => (
          <NavItem
            key={item.path}
            to={item.path}
            icon={item.icon}
            onClick={onClose}
            subItems={item.subItems}
            collapsed={collapsed}
            className={item.className}
          >
            {item.label}
          </NavItem>
        ))}
      </nav>
    </div>
  );
}