import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '@/lib/supabase';
import { Loader2 } from 'lucide-react';

export function AuthCallback() {
  const navigate = useNavigate();

  useEffect(() => {
    const handleAuthCallback = async () => {
      try {
        // Get session from URL
        const { data: { session }, error } = await supabase.auth.getSession();
        
        if (error) {
          console.error('Auth callback error:', error);
          navigate('/login?error=Authentication failed');
          return;
        }

        if (!session) {
          navigate('/login?error=No session found');
          return;
        }

        // Check if user profile exists
        const { data: profile, error: profileError } = await supabase
          .from('user_profiles')
          .select('*')
          .eq('id', session.user.id)
          .single();

        if (profileError && profileError.code !== 'PGRST116') { // PGRST116 is "not found"
          console.error('Profile fetch error:', profileError);
          navigate('/login?error=Profile fetch failed');
          return;
        }

        // Only create profile if it doesn't exist
        if (!profile) {
          const { error: insertError } = await supabase
            .from('user_profiles')
            .upsert({
              id: session.user.id,
              email: session.user.email,
              name: session.user.user_metadata?.full_name || session.user.email?.split('@')[0] || null,
              avatar_url: session.user.user_metadata?.avatar_url || null,
              credits: 300, // Initial credits only for new users
              created_at: new Date().toISOString(),
              updated_at: new Date().toISOString(),
              completed_onboarding: false, // Add onboarding fields
              onboarding_step: 0,
            } as { 
              id: string; 
              email: string; 
              name: string | null; 
              avatar_url: string | null; 
              credits: number; 
              created_at: string; 
              updated_at: string;
              completed_onboarding: boolean;
              onboarding_step: number;
            },
            { onConflict: 'id', ignoreDuplicates: false });

          if (insertError) {
            console.error('Profile creation error:', insertError);
            console.log('Proceeding despite profile error');
          }
        }

        // Successful authentication, redirect to dashboard
        navigate('/dashboard', { replace: true });
      } catch (error) {
        console.error('Auth callback processing error:', error);
        navigate('/login?error=Authentication processing failed');
      }
    };

    handleAuthCallback();
  }, [navigate]);

  return (
    <div className="flex items-center justify-center min-h-screen">
      <Loader2 className="h-8 w-8 animate-spin" />
    </div>
  );
}
