import { supabase } from './supabase';
import type { EmailProviders, ProviderConfig, ProviderName } from '@/types/providers';

function normalizeHost(host: string): string {
  // Remove trailing slash if present
  host = host.endsWith('/') ? host.slice(0, -1) : host;
  
  // Ensure host starts with http:// or https://
  if (!host.startsWith('http://') && !host.startsWith('https://')) {
    host = `https://${host}`;
  }
  
  return host;
}

export class ProviderSettings {
  private static instance: ProviderSettings;
  private providers: EmailProviders | null = null;
  private lastFetch: Date | null = null;
  private cacheDuration = 5 * 60 * 1000; // 5 minutes

  private constructor() {}

  public static getInstance(): ProviderSettings {
    if (!ProviderSettings.instance) {
      ProviderSettings.instance = new ProviderSettings();
    }
    return ProviderSettings.instance;
  }

  private isCacheValid(): boolean {
    if (!this.lastFetch || !this.providers) return false;
    const now = new Date();
    return now.getTime() - this.lastFetch.getTime() < this.cacheDuration;
  }

  public async getProviders(): Promise<EmailProviders | null> {
    if (this.isCacheValid()) {
      return this.providers;
    }

    try {
      // Get active providers from api_providers table
      const { data: apiProviders, error } = await supabase
        .from('api_providers')
        .select('id, name, host, provider_type, daily_limit, is_active, priority, requests_today, last_used_at')
        .eq('is_active', true)
        .order('priority', { ascending: true });

      if (error) {
        console.error('Error fetching providers:', error);
        return null;
      }

      if (!apiProviders || apiProviders.length === 0) {
        console.error('No active providers found');
        return null;
      }

      // Initialize providers object
      const providers: EmailProviders = {
        gmail: {
          enabled: false,
          endpoint: '',
          dailyLimit: 0,
          priority: 0,
          providerType: 'gmail'
        },
        microsoft: {
          enabled: false,
          endpoint: '',
          dailyLimit: 0,
          priority: 0,
          providerType: 'microsoft'
        },
        yahoo: {
          enabled: false,
          endpoint: '',
          dailyLimit: 0,
          priority: 0,
          providerType: 'yahoo'
        },
        others: {
          enabled: false,
          endpoint: '',
          dailyLimit: 0,
          priority: 0,
          providerType: 'generic'
        }
      };

      // Update providers with active configurations
      apiProviders.forEach(provider => {
        const config: ProviderConfig = {
          enabled: provider.is_active,
          endpoint: normalizeHost(provider.host),
          dailyLimit: provider.daily_limit,
          priority: provider.priority,
          providerType: provider.provider_type as ProviderName,
          requestsToday: provider.requests_today,
          lastUsed: provider.last_used_at ? new Date(provider.last_used_at) : null
        };

        switch (provider.provider_type) {
          case 'gmail':
            providers.gmail = config;
            break;
          case 'microsoft':
            providers.microsoft = config;
            break;
          case 'yahoo':
            providers.yahoo = config;
            break;
          default:
            providers.others = config;
        }
      });

      this.providers = providers;
      this.lastFetch = new Date();
      return providers;
    } catch (error) {
      console.error('Error in getProviders:', error);
      return null;
    }
  }

  public async refreshProviders(): Promise<void> {
    this.lastFetch = null;
    await this.getProviders();
  }
}

export const providerSettings = ProviderSettings.getInstance();
