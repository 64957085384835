import { useState, useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAppContext } from '@/contexts/AppContext';
import { Loader2 } from 'lucide-react';

export function AdminRoute({ children }: { children: React.ReactNode }) {
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const { adminAuth, isAdmin } = useAppContext();

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsLoading(false);
    }, 1000); // Short timeout to ensure context is loaded

    return () => clearTimeout(timeoutId);
  }, []);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <Loader2 className="h-8 w-8 animate-spin" />
      </div>
    );
  }

  if (!adminAuth.isAuthenticated || !isAdmin) {
    return <Navigate to="/admin/login" state={{ from: location }} replace />;
  }

  return <>{children}</>;
}