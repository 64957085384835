import { supabase } from './supabase';
import { validationService } from './validationService';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import type { 
  ValidationResult, 
  ValidationHistoryRecord, 
  ValidationStatus,
  ValidationFilters,
  ValidationIssues,
  ProviderName,
  EdgeFunctionValidationResult
} from '@/types/providers';

interface ValidationHistoryResponse {
  results: ValidationHistoryRecord[];
  total: number;
  pages: number;
}

interface DeductCreditsResult {
  success: boolean;
  error?: string;
  deducted: number;
  remaining: number;
}

export async function validateEmails(
  emails: string[], 
  fileName?: string
): Promise<ValidationResult> {
  try {
    const { data: { user } } = await supabase.auth.getUser();
    if (!user) throw new Error('No authenticated user');

    // Get the user's IP address
    const ipAddress = window.sessionStorage.getItem('userIp') || '0.0.0.0';

    const response = await validationService.validateEmails(emails, user.id, ipAddress);
    if (!response || !Array.isArray(response) || response.length === 0) {
      throw new Error('Invalid validation response');
    }

    // Combine all results into one
    const combinedResult: ValidationResult = {
      id: uuidv4(),
      date: new Date(),
      status: 'completed' as ValidationStatus,
      totalEmails: emails.length,
      creditsUsed: emails.length,
      valid: response.filter((r: EdgeFunctionValidationResult) => r.valid).map(r => r.email),
      invalid: response.filter((r: EdgeFunctionValidationResult) => !r.valid).map(r => r.email),
      risky: [],
      disposable: response.filter((r: EdgeFunctionValidationResult) => r.disposable).map(r => r.email),
      spamTraps: [],
      confidence: response.reduce((acc, r: EdgeFunctionValidationResult) => acc + (r.confidence || 0), 0) / response.length,
      issues: response.find((r: EdgeFunctionValidationResult) => r.issues)?.issues,
      provider: response[0]?.provider || 'internal',
      response_time: 0,
      validation_result: JSON.stringify(response)
    };

    return combinedResult;
  } catch (error) {
    console.error('Error validating emails:', error);
    throw error;
  }
}

export async function validateSingleEmail(email: string): Promise<ValidationResult> {
  try {
    const { data: { user } } = await supabase.auth.getUser();
    if (!user) throw new Error('No authenticated user');

    // Get the user's IP address
    const ipAddress = window.sessionStorage.getItem('userIp') || '0.0.0.0';

    const response = await validationService.validateEmails(email, user.id, ipAddress);
    if (!response || !Array.isArray(response) || response.length === 0) {
      throw new Error('Invalid validation response');
    }

    // Convert the single result to ValidationResult format
    const result = response[0] as EdgeFunctionValidationResult;
    return {
      id: result.id,
      date: new Date(),
      status: 'completed',
      totalEmails: 1,
      creditsUsed: 1,
      valid: result.valid ? [email] : [],
      invalid: !result.valid ? [email] : [],
      risky: [],
      disposable: result.disposable ? [email] : [],
      spamTraps: [],
      confidence: result.confidence || 0,
      issues: result.issues,
      provider: result.provider,
      response_time: 0,
      validation_result: JSON.stringify(response)
    };
  } catch (error) {
    console.error('Error validating email:', error);
    throw error;
  }
}

export const getUserCredits = async (): Promise<number> => {
  try {
    // Get the current user first
    const { data: { user } } = await supabase.auth.getUser();
    if (!user) throw new Error('No authenticated user');

    // Get user's credits
    const { data: userProfile, error } = await supabase
      .from('user_profiles')
      .select('credits')
      .eq('id', user.id)
      .single();

    if (error) throw error;

    const credits = userProfile?.credits || 0;
    
    // Update localStorage and trigger storage event
    localStorage.setItem('userCredits', credits.toString());
    // Use a custom event instead of StorageEvent for better cross-component communication
    window.dispatchEvent(new CustomEvent('creditUpdate', { 
      detail: { credits }
    }));
    
    return credits;
  } catch (error) {
    console.error('Failed to get user credits:', error);
    return 0;
  }
};

export async function getValidationHistory(filters: ValidationFilters): Promise<ValidationHistoryResponse> {
  try {
    const { data: { user } } = await supabase.auth.getUser();
    if (!user) throw new Error('No authenticated user');

    let query = supabase
      .from('validation_history')
      .select('*', { count: 'exact' })
      .order('created_at', { ascending: false });

    // Add user filter for non-admin users
    if (!filters.isAdmin) {
      query = query.eq('user_id', user.id);
    }

    // Apply filters
    if (filters.status && filters.status !== 'all') {
      query = query.eq('status', filters.status);
    }

    if (filters.search) {
      query = query.ilike('file_name', `%${filters.search}%`);
    }

    // Apply sorting
    if (filters.sort) {
      switch (filters.sort) {
        case 'oldest':
          query = query.order('created_at', { ascending: true });
          break;
        case 'largest':
          query = query.order('total_emails', { ascending: false });
          break;
        case 'smallest':
          query = query.order('total_emails', { ascending: true });
          break;
        default: // 'newest' is default
          query = query.order('created_at', { ascending: false });
      }
    }

    // Apply pagination
    const pageSize = filters.pageSize || 20;
    const page = filters.page || 1;
    const start = (page - 1) * pageSize;
    
    const { data, error, count } = await query.range(start, start + pageSize - 1);

    if (error) throw error;

    // Transform data to match ValidationHistoryRecord type
    const results = data.map(record => ({
      id: record.id,
      user_id: record.user_id,
      total_emails: record.total_emails || 0,
      valid_emails: record.valid_emails || [],
      invalid_emails: record.invalid_emails || [],
      risky_emails: record.risky_emails || [],
      disposable_emails: record.disposable_emails || [],
      spam_trap_emails: record.spam_trap_emails || [],
      file_name: record.file_name,
      credits_used: record.credits_used || 0,
      status: record.status,
      confidence: record.confidence || 0,
      provider: record.provider || 'unknown',
      issues: record.issues,
      response_time: record.response_time || 0,
      validation_result: record.validation_result || '',
      created_at: record.created_at,
      updated_at: record.updated_at
    }));

    return {
      results,
      total: count || 0,
      pages: Math.ceil((count || 0) / pageSize)
    };

  } catch (error) {
    console.error('Error fetching validation history:', error);
    throw error;
  }
}

export async function downloadValidationReport(id: string): Promise<Blob> {
  try {
    const { data: { user } } = await supabase.auth.getUser();
    if (!user) throw new Error('No authenticated user');

    const { data, error } = await supabase
      .from('validation_history')
      .select('*')
      .eq('id', id)
      .eq('user_id', user.id)
      .single();

    if (error) throw error;
    if (!data) throw new Error('Validation not found');

    const record: ValidationHistoryRecord = {
      id: data.id,
      user_id: data.user_id,
      email: data.email,
      total_emails: data.total_emails,
      valid_emails: data.valid_emails || [],
      invalid_emails: data.invalid_emails || [],
      risky_emails: data.risky_emails || [],
      disposable_emails: data.disposable_emails || [],
      spam_trap_emails: data.spam_trap_emails || [],
      file_name: data.file_name,
      credits_used: data.credits_used,
      status: data.status as ValidationStatus,
      confidence: data.confidence,
      provider: data.provider,
      issues: data.issues,
      response_time: data.response_time,
      validation_result: data.validation_result,
      created_at: data.created_at,
      updated_at: data.updated_at
    };

    const rows = [
      ['Email', 'Status'],
      ...record.valid_emails.map(email => [email, 'Valid']),
      ...record.invalid_emails.map(email => [email, 'Invalid']),
      ...record.risky_emails.map(email => [email, 'Risky']),
      ...record.disposable_emails.map(email => [email, 'Disposable']),
      ...record.spam_trap_emails.map(email => [email, 'Spam Trap'])
    ];

    const csv = rows.map(row => row.join(',')).join('\n');
    return new Blob([csv], { type: 'text/csv' });
  } catch (error) {
    console.error('Error downloading validation report:', error);
    throw error;
  }
}