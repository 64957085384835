import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import { Badge } from '@/components/ui/badge';
import { getUserTickets } from '@/lib/support';
import { SupportTicket } from '@/lib/types/support';
import { formatDistanceToNow } from 'date-fns';
import {
  AlertCircle,
  Clock,
  CheckCircle2,
  XCircle,
  AlertTriangle,
  Loader2,
  Plus,
} from 'lucide-react';
import { cn } from '@/lib/utils';

const STATUS_CONFIG = {
  open: {
    color: 'bg-emerald-100 text-emerald-700 hover:bg-emerald-200',
    icon: AlertCircle,
    label: 'Open',
  },
  in_progress: {
    color: 'bg-blue-100 text-blue-700 hover:bg-blue-200',
    icon: Clock,
    label: 'In Progress',
  },
  resolved: {
    color: 'bg-gray-100 text-gray-700 hover:bg-gray-200',
    icon: CheckCircle2,
    label: 'Resolved',
  },
  closed: {
    color: 'bg-red-100 text-red-700 hover:bg-red-200',
    icon: XCircle,
    label: 'Closed',
  },
};

const PRIORITY_CONFIG = {
  low: {
    color: 'bg-gray-100 text-gray-700',
    icon: AlertCircle,
    label: 'Low',
  },
  medium: {
    color: 'bg-yellow-100 text-yellow-700',
    icon: AlertCircle,
    label: 'Medium',
  },
  high: {
    color: 'bg-orange-100 text-orange-700',
    icon: AlertTriangle,
    label: 'High',
  },
  urgent: {
    color: 'bg-red-100 text-red-700',
    icon: AlertTriangle,
    label: 'Urgent',
  },
};

export function TicketList() {
  const navigate = useNavigate();
  const [tickets, setTickets] = useState<SupportTicket[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    loadTickets();
  }, []);

  const loadTickets = async () => {
    try {
      const data = await getUserTickets();
      setTickets(data);
    } catch (error) {
      console.error('Error loading tickets:', error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center p-8">
        <Loader2 className="h-8 w-8 animate-spin text-gray-500" />
      </div>
    );
  }

  return (
    <div className="space-y-4">
      <div className="flex justify-between items-center">
        <div className="space-y-1">
          <h2 className="text-2xl font-bold tracking-tight">My Support Tickets</h2>
          <p className="text-muted-foreground">
            View and manage your support tickets
          </p>
        </div>
        <Button onClick={() => navigate('/support/tickets/new')}>
          <Plus className="h-4 w-4 mr-2" />
          New Ticket
        </Button>
      </div>

      <div className="rounded-md border">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>ID</TableHead>
              <TableHead>Subject</TableHead>
              <TableHead>Status</TableHead>
              <TableHead>Priority</TableHead>
              <TableHead>Created</TableHead>
              <TableHead>Updated</TableHead>
              <TableHead className="text-right">Actions</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {tickets.length === 0 ? (
              <TableRow>
                <TableCell colSpan={7} className="text-center py-8">
                  <div className="text-muted-foreground">
                    No support tickets found. Create a new ticket to get help.
                  </div>
                </TableCell>
              </TableRow>
            ) : (
              tickets.map((ticket) => {
                const StatusIcon = STATUS_CONFIG[ticket.status].icon;
                const PriorityIcon = PRIORITY_CONFIG[ticket.priority].icon;

                return (
                  <TableRow key={ticket.id}>
                    <TableCell className="font-mono">
                      {ticket.id.slice(0, 8)}
                    </TableCell>
                    <TableCell className="max-w-[300px] truncate">
                      {ticket.subject}
                    </TableCell>
                    <TableCell>
                      <Badge
                        variant="secondary"
                        className={cn(
                          'flex w-fit items-center gap-1',
                          STATUS_CONFIG[ticket.status].color
                        )}
                      >
                        <StatusIcon className="h-3 w-3" />
                        {STATUS_CONFIG[ticket.status].label}
                      </Badge>
                    </TableCell>
                    <TableCell>
                      <Badge
                        variant="secondary"
                        className={cn(
                          'flex w-fit items-center gap-1',
                          PRIORITY_CONFIG[ticket.priority].color
                        )}
                      >
                        <PriorityIcon className="h-3 w-3" />
                        {PRIORITY_CONFIG[ticket.priority].label}
                      </Badge>
                    </TableCell>
                    <TableCell className="text-muted-foreground">
                      {formatDistanceToNow(new Date(ticket.created_at), {
                        addSuffix: true,
                      })}
                    </TableCell>
                    <TableCell className="text-muted-foreground">
                      {formatDistanceToNow(new Date(ticket.updated_at), {
                        addSuffix: true,
                      })}
                    </TableCell>
                    <TableCell className="text-right">
                      <Button
                        variant="outline"
                        size="sm"
                        onClick={() => navigate(`/support/tickets/${ticket.id}`)}
                      >
                        View Details
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </div>
    </div>
  );
}
