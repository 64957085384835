import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '@/lib/supabase';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { useToast } from '@/components/ui/use-toast';
import { Loader2, Download, Eye } from 'lucide-react';
import { Badge } from '@/components/ui/badge';
import { format } from 'date-fns';
import { Input } from '@/components/ui/input';
import { ValidationHistoryRecord } from '@/types/providers';

const PAGE_SIZE = 20;

export function ValidationHistory() {
  const navigate = useNavigate();
  const [validations, setValidations] = useState<ValidationHistoryRecord[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [search, setSearch] = useState('');
  const { toast } = useToast();

  const loadValidations = async () => {
    try {
      setIsLoading(true);
      const from = (currentPage - 1) * PAGE_SIZE;
      const to = from + PAGE_SIZE - 1;

      // Get current user
      const { data: { session }, error: sessionError } = await supabase.auth.getSession();
      if (sessionError) throw sessionError;
      if (!session?.user) throw new Error('No user session found');

      let query = supabase
        .from('validation_history')
        .select('*', { count: 'exact' })
        .eq('user_id', session.user.id)
        .order('created_at', { ascending: false });

      if (search) {
        query = query.or(`file_name.ilike.%${search}%,email.ilike.%${search}%`);
      }

      const { data, error, count } = await query.range(from, to);

      if (error) {
        console.error('Error loading validations:', error);
        throw error;
      }

      // Transform data to match ValidationHistoryRecord type
      const transformedData = data?.map(record => ({
        ...record,
        total_emails: record.total_emails || 0,
        valid_emails: Array.isArray(record.valid_emails) ? record.valid_emails : [],
        invalid_emails: Array.isArray(record.invalid_emails) ? record.invalid_emails : [],
        risky_emails: Array.isArray(record.risky_emails) ? record.risky_emails : [],
        disposable_emails: Array.isArray(record.disposable_emails) ? record.disposable_emails : [],
        spam_trap_emails: Array.isArray(record.spam_trap_emails) ? record.spam_trap_emails : [],
        file_name: record.file_name || 'Untitled',
        credits_used: record.credits_used || 0,
        status: record.status || 'unknown',
        confidence: record.confidence || 0,
        provider: record.provider || 'unknown',
        issues: record.issues || [],
        response_time: record.response_time || 0,
        validation_result: record.validation_result || '',
        created_at: record.created_at,
        updated_at: record.updated_at
      })) || [];

      setValidations(transformedData);
      setTotalPages(Math.ceil((count || 0) / PAGE_SIZE));
    } catch (error) {
      console.error('Error:', error);
      toast({
        title: 'Error',
        description: 'Failed to load validation history',
        variant: 'destructive',
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadValidations();
  }, [currentPage, search]);

  const renderStatus = (status: string) => {
    switch (status) {
      case 'completed':
        return <Badge variant="default" className="bg-green-100 text-green-800">Completed</Badge>;
      case 'failed':
        return <Badge variant="destructive">Failed</Badge>;
      case 'processing':
        return <Badge variant="secondary">Processing</Badge>;
      default:
        return <Badge variant="secondary">{status}</Badge>;
    }
  };

  const handleDownload = (validation: ValidationHistoryRecord) => {
    try {
      const timestamp = format(new Date(validation.created_at), 'yyyy-MM-dd-HHmm');
      const validContent = validation.valid_emails.join('\n');
      const invalidContent = validation.invalid_emails.join('\n');
      const riskyContent = validation.risky_emails.join('\n');

      // Create a combined report
      const reportContent = `Validation Report - ${validation.file_name}\n` +
        `Date: ${format(new Date(validation.created_at), 'PPpp')}\n` +
        `Status: ${validation.status}\n` +
        `Response Time: ${validation.response_time}ms\n` +
        `Confidence: ${(validation.confidence * 100).toFixed(1)}%\n` +
        `Credits Used: ${validation.credits_used}\n\n` +
        `Valid Emails (${validation.valid_emails.length}):\n${validContent}\n\n` +
        `Invalid Emails (${validation.invalid_emails.length}):\n${invalidContent}\n\n` +
        `Risky Emails (${validation.risky_emails.length}):\n${riskyContent}`;

      const blob = new Blob([reportContent], { type: 'text/plain' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `validation-report-${timestamp}.txt`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);

      toast({
        title: 'Success',
        description: 'Report downloaded successfully',
      });
    } catch (error) {
      console.error('Download error:', error);
      toast({
        title: 'Error',
        description: 'Failed to download report',
        variant: 'destructive',
      });
    }
  };

  const handlePreview = (validation: ValidationHistoryRecord) => {
    navigate('/validation/results', {
      state: { 
        validationResult: {
          id: validation.id,
          date: new Date(validation.created_at),
          status: validation.status,
          totalEmails: validation.total_emails,
          creditsUsed: validation.credits_used,
          valid: validation.valid_emails,
          invalid: validation.invalid_emails,
          risky: validation.risky_emails,
          disposable: validation.disposable_emails,
          spamTraps: validation.spam_trap_emails,
          confidence: validation.confidence,
          provider: validation.provider,
          issues: validation.issues,
          response_time: validation.response_time,
          validation_result: validation.validation_result,
          metadata: {
            fileName: validation.file_name
          }
        }
      }
    });
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle>Validation History</CardTitle>
        <CardDescription>View your email validation history</CardDescription>
      </CardHeader>
      <CardContent>
        <div className="mb-4">
          <Input
            placeholder="Search by file name or email..."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            className="max-w-sm"
          />
        </div>

        {isLoading ? (
          <div className="flex justify-center py-8">
            <Loader2 className="h-8 w-8 animate-spin" />
          </div>
        ) : (
          <>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Date</TableHead>
                  <TableHead>File</TableHead>
                  <TableHead>Total</TableHead>
                  <TableHead>Results</TableHead>
                  <TableHead>Status</TableHead>
                  <TableHead>Actions</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {validations.map((validation) => (
                  <TableRow key={validation.id}>
                    <TableCell>
                      {format(new Date(validation.created_at), 'PPp')}
                    </TableCell>
                    <TableCell>{validation.file_name}</TableCell>
                    <TableCell>{validation.total_emails}</TableCell>
                    <TableCell>
                      <div className="flex gap-2">
                        <Badge variant="secondary" className="bg-green-100 text-green-800">
                          {validation.valid_emails.length} valid
                        </Badge>
                        <Badge variant="secondary" className="bg-red-100 text-red-800">
                          {validation.invalid_emails.length} invalid
                        </Badge>
                        <Badge variant="secondary" className="bg-yellow-100 text-yellow-800">
                          {validation.risky_emails.length} risky
                        </Badge>
                      </div>
                    </TableCell>
                    <TableCell>{renderStatus(validation.status)}</TableCell>
                    <TableCell>
                      <div className="flex gap-2">
                        <Button
                          variant="outline"
                          size="sm"
                          onClick={() => handlePreview(validation)}
                        >
                          <Eye className="h-4 w-4 mr-1" />
                          Preview
                        </Button>
                        <Button
                          variant="outline"
                          size="sm"
                          onClick={() => handleDownload(validation)}
                        >
                          <Download className="h-4 w-4 mr-1" />
                          Download
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>

            {validations.length === 0 && (
              <div className="text-center py-8 text-muted-foreground">
                No validations found
              </div>
            )}

            {totalPages > 1 && (
              <div className="flex justify-between items-center mt-4">
                <Button
                  variant="outline"
                  size="sm"
                  onClick={() => setCurrentPage(p => Math.max(1, p - 1))}
                  disabled={currentPage === 1}
                >
                  Previous
                </Button>
                <div className="text-sm text-muted-foreground">
                  Page {currentPage} of {totalPages}
                </div>
                <Button
                  variant="outline"
                  size="sm"
                  onClick={() => setCurrentPage(p => p + 1)}
                  disabled={currentPage === totalPages}
                >
                  Next
                </Button>
              </div>
            )}
          </>
        )}
      </CardContent>
    </Card>
  );
}