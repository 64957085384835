import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import { Badge } from '@/components/ui/badge';
import { getAllTickets } from '@/lib/support';
import { SupportTicket } from '@/lib/types/support';
import { formatDistanceToNow } from 'date-fns';
import {
  AlertCircle,
  Clock,
  CheckCircle2,
  XCircle,
  AlertTriangle,
  Loader2,
  Search,
  RefreshCw,
} from 'lucide-react';
import { cn } from '@/lib/utils';
import { useToast } from '@/components/ui/use-toast';

const STATUS_CONFIG = {
  open: {
    color: 'bg-emerald-100 text-emerald-700 hover:bg-emerald-200',
    icon: AlertCircle,
    label: 'Open',
  },
  in_progress: {
    color: 'bg-blue-100 text-blue-700 hover:bg-blue-200',
    icon: Clock,
    label: 'In Progress',
  },
  resolved: {
    color: 'bg-gray-100 text-gray-700 hover:bg-gray-200',
    icon: CheckCircle2,
    label: 'Resolved',
  },
  closed: {
    color: 'bg-red-100 text-red-700 hover:bg-red-200',
    icon: XCircle,
    label: 'Closed',
  },
};

const PRIORITY_CONFIG = {
  low: {
    color: 'bg-gray-100 text-gray-700',
    icon: AlertCircle,
    label: 'Low',
  },
  medium: {
    color: 'bg-yellow-100 text-yellow-700',
    icon: AlertCircle,
    label: 'Medium',
  },
  high: {
    color: 'bg-orange-100 text-orange-700',
    icon: AlertTriangle,
    label: 'High',
  },
  urgent: {
    color: 'bg-red-100 text-red-700',
    icon: AlertTriangle,
    label: 'Urgent',
  },
};

export function AdminTicketList() {
  const navigate = useNavigate();
  const { toast } = useToast();
  const [tickets, setTickets] = useState<SupportTicket[]>([]);
  const [filteredTickets, setFilteredTickets] = useState<SupportTicket[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [statusFilter, setStatusFilter] = useState<string>('all');
  const [priorityFilter, setPriorityFilter] = useState<string>('all');

  const loadTickets = async () => {
    try {
      setLoading(true);
      setError(null);
      const data = await getAllTickets();
      setTickets(data);
      setFilteredTickets(data);
    } catch (error) {
      console.error('Error loading tickets:', error);
      setError(error instanceof Error ? error.message : 'Failed to load tickets');
      toast({
        title: "Error loading tickets",
        description: error instanceof Error ? error.message : 'Failed to load tickets',
        variant: "destructive",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadTickets();
  }, []);

  useEffect(() => {
    filterTickets();
  }, [tickets, searchQuery, statusFilter, priorityFilter]);

  const filterTickets = () => {
    let filtered = [...tickets];

    // Apply search filter
    if (searchQuery) {
      filtered = filtered.filter(
        ticket =>
          ticket.subject.toLowerCase().includes(searchQuery.toLowerCase()) ||
          ticket.description.toLowerCase().includes(searchQuery.toLowerCase()) ||
          ticket.profiles?.email.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    // Apply status filter
    if (statusFilter !== 'all') {
      filtered = filtered.filter(ticket => ticket.status === statusFilter);
    }

    // Apply priority filter
    if (priorityFilter !== 'all') {
      filtered = filtered.filter(ticket => ticket.priority === priorityFilter);
    }

    setFilteredTickets(filtered);
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center p-8">
        <Loader2 className="h-8 w-8 animate-spin text-gray-500" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex flex-col items-center justify-center gap-4 p-8">
        <div className="text-red-500 flex items-center gap-2">
          <AlertCircle className="h-5 w-5" />
          <span>{error}</span>
        </div>
        <Button
          variant="outline"
          onClick={loadTickets}
          className="flex items-center gap-2"
        >
          <RefreshCw className="h-4 w-4" />
          Try Again
        </Button>
      </div>
    );
  }

  return (
    <div className="space-y-4">
      <div className="flex justify-between items-center">
        <div className="space-y-1">
          <h2 className="text-2xl font-bold tracking-tight">Manage Support Tickets</h2>
          <p className="text-muted-foreground">
            View and manage all support tickets
          </p>
        </div>
        <Button
          variant="outline"
          size="icon"
          onClick={loadTickets}
          className="h-8 w-8"
        >
          <RefreshCw className="h-4 w-4" />
        </Button>
      </div>

      <div className="flex gap-4 items-center">
        <div className="relative flex-1">
          <Search className="absolute left-2 top-2.5 h-4 w-4 text-muted-foreground" />
          <Input
            placeholder="Search tickets..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="pl-8"
          />
        </div>
        <Select
          value={statusFilter}
          onValueChange={setStatusFilter}
        >
          <SelectTrigger className="w-[180px]">
            <SelectValue placeholder="All Statuses" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="all">All Statuses</SelectItem>
            <SelectItem value="open">Open</SelectItem>
            <SelectItem value="in_progress">In Progress</SelectItem>
            <SelectItem value="resolved">Resolved</SelectItem>
            <SelectItem value="closed">Closed</SelectItem>
          </SelectContent>
        </Select>
        <Select
          value={priorityFilter}
          onValueChange={setPriorityFilter}
        >
          <SelectTrigger className="w-[180px]">
            <SelectValue placeholder="All Priorities" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="all">All Priorities</SelectItem>
            <SelectItem value="low">Low</SelectItem>
            <SelectItem value="medium">Medium</SelectItem>
            <SelectItem value="high">High</SelectItem>
            <SelectItem value="urgent">Urgent</SelectItem>
          </SelectContent>
        </Select>
      </div>

      <div className="rounded-md border">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Subject</TableHead>
              <TableHead>Status</TableHead>
              <TableHead>Priority</TableHead>
              <TableHead>Category</TableHead>
              <TableHead>User</TableHead>
              <TableHead>Created</TableHead>
              <TableHead>Last Updated</TableHead>
              <TableHead className="text-right">Actions</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {filteredTickets.length === 0 ? (
              <TableRow>
                <TableCell colSpan={8} className="text-center py-8">
                  <div className="text-muted-foreground">
                    No tickets found matching your filters.
                  </div>
                </TableCell>
              </TableRow>
            ) : (
              filteredTickets.map((ticket) => {
                const StatusIcon = STATUS_CONFIG[ticket.status].icon;
                const PriorityIcon = PRIORITY_CONFIG[ticket.priority].icon;

                return (
                  <TableRow key={ticket.id}>
                    <TableCell className="max-w-[300px] truncate font-medium">
                      {ticket.subject}
                    </TableCell>
                    <TableCell>
                      <Badge
                        variant="secondary"
                        className={cn(
                          'flex w-fit items-center gap-1',
                          STATUS_CONFIG[ticket.status].color
                        )}
                      >
                        <StatusIcon className="h-3 w-3" />
                        {STATUS_CONFIG[ticket.status].label}
                      </Badge>
                    </TableCell>
                    <TableCell>
                      <Badge
                        variant="secondary"
                        className={cn(
                          'flex w-fit items-center gap-1',
                          PRIORITY_CONFIG[ticket.priority].color
                        )}
                      >
                        <PriorityIcon className="h-3 w-3" />
                        {PRIORITY_CONFIG[ticket.priority].label}
                      </Badge>
                    </TableCell>
                    <TableCell>{ticket.category}</TableCell>
                    <TableCell className="font-medium">
                      {ticket.profiles?.email}
                    </TableCell>
                    <TableCell className="text-muted-foreground">
                      {formatDistanceToNow(new Date(ticket.created_at), {
                        addSuffix: true,
                      })}
                    </TableCell>
                    <TableCell className="text-muted-foreground">
                      {formatDistanceToNow(new Date(ticket.updated_at), {
                        addSuffix: true,
                      })}
                    </TableCell>
                    <TableCell className="text-right">
                      <Button
                        variant="outline"
                        size="sm"
                        onClick={() => navigate(`/admin/support/tickets/${ticket.id}`)}
                      >
                        View
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </div>
    </div>
  );
}
