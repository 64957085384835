import { supabase } from './supabase';
import { Database, ValidationStats, ValidationCount, AdminDashboardStats } from './database.types';
import { PostgrestResponse, PostgrestSingleResponse } from '@supabase/supabase-js';

type Tables = Database['public']['Tables']
type UserProfile = Tables['user_profiles']['Row']
type CreditPackage = Tables['credit_packages']['Row']
type PaymentGateway = Tables['payment_gateways']['Row']

// Update Database interface to include Functions
interface DatabaseWithFunctions extends Database {
  public: Database['public'] & {
    Functions: {
      get_user_validation_counts: {
        Args: { user_ids: string[] };
        Returns: ValidationCount[];
      };
      get_admin_dashboard_stats: {
        Args: Record<string, never>;
        Returns: AdminDashboardStats;
      };
    };
  };
}

// User Management
export async function getUsers(page = 1, limit = 10, searchTerm = '') {
  try {
    const { data: { session } } = await supabase.auth.getSession();
    if (!session?.user) {
      throw new Error('No authenticated session');
    }

    const start = (page - 1) * limit;
    const end = start + limit - 1;

    // Get users with a simpler query
    let query = supabase
      .from('user_profiles')
      .select('*', { count: 'exact' });

    if (searchTerm) {
      query = query.ilike('email', `%${searchTerm}%`);
    }

    const { data: users, error, count } = await query
      .range(start, end)
      .order('created_at', { ascending: false });

    if (error) throw error;

    // Get validation counts from validation_history table
    const { data: validationCounts, error: validationError } = await supabase
      .from('validation_history')
      .select('user_id, total_emails')
      .in('user_id', users?.map(u => u.id) || []);

    if (validationError) {
      console.error('Error fetching validation counts:', validationError);
    }

    // Create a map of user_id to their total validations
    const userValidations = new Map();
    validationCounts?.forEach(vc => {
      // Count number of validations (rows) per user
      const currentCount = userValidations.get(vc.user_id)?.count || 0;
      const currentTotal = userValidations.get(vc.user_id)?.total || 0;
      userValidations.set(vc.user_id, {
        count: currentCount + 1, // Increment validation count
        total: currentTotal + (vc.total_emails || 0) // Add total emails validated
      });
    });

    const enrichedUsers = users?.map(user => ({
      id: user.id,
      email: user.email,
      credits: user.credits ?? 0,
      created_at: user.created_at,
      is_banned: user.is_banned ?? false,
      email_validations: {
        count: userValidations.get(user.id)?.total || 0 // Use total emails validated
      }
    })) || [];

    return {
      users: enrichedUsers,
      total: count || 0,
      page,
      totalPages: Math.ceil((count || 0) / limit)
    };

  } catch (error) {
    console.error('Error in getUsers:', error);
    throw error;
  }
}

// Add this function for validation counts with proper typing
export async function getUserValidationCounts() {
  try {
    const { data, error } = await supabase
      .rpc('get_user_validation_counts') as PostgrestSingleResponse<ValidationCount[]>;

    if (error) throw error;
    return data || [];
  } catch (error) {
    console.error('Error fetching validation counts:', error);
    return [];
  }
}

// Add this interface to handle the raw data from the database
interface RawAdminStats {
  totalUsers: number;
  activeUsers: number;
  totalValidations: number;
  validEmails: number;
  invalidEmails: number;
  riskyEmails: number;
  totalRevenue: number;
  chartData?: Array<{
    date: string;
    validations: number;
    valid: number;
    invalid: number;
    risky: number;
    revenue: number;
  }>;
}

// Update the dashboard stats function with proper typing
export async function getAdminDashboardStats() {
  try {
    console.log('Fetching admin dashboard stats...');
    
    const { data: { session } } = await supabase.auth.getSession();
    if (!session?.user) {
      throw new Error('No authenticated session');
    }

    // Check admin status from user_profiles
    const { data: adminUser, error: adminError } = await supabase
      .from('user_profiles')
      .select('is_admin')
      .eq('id', session.user.id)
      .single();

    if (adminError || !adminUser?.is_admin) {
      throw new Error('Unauthorized access');
    }

    // Call the new database function
    const { data: rawStats, error } = await supabase
      .rpc('get_admin_dashboard_stats');

    if (error) {
      console.error('Stats fetch error:', error);
      throw error;
    }

    console.log('Raw stats received:', rawStats);

    // Ensure we have valid data
    const transformedStats: AdminDashboardStats = {
      totalUsers: rawStats?.totalUsers || 0,
      activeUsers: rawStats?.activeUsers || 0,
      totalValidations: rawStats?.totalValidations || 0,
      validEmails: rawStats?.validEmails || 0,
      invalidEmails: rawStats?.invalidEmails || 0,
      riskyEmails: rawStats?.riskyEmails || 0,
      totalRevenue: rawStats?.totalRevenue || 0,
      chartData: rawStats?.chartData?.map((item: { date: string; validations: number; valid: number; invalid: number; risky: number; revenue: number; }) => ({
        date: item.date,
        validations: item.validations || 0,
        valid: item.valid || 0,
        invalid: item.invalid || 0,
        risky: item.risky || 0,
        revenue: item.revenue || 0
      })) || []
    };

    console.log('Transformed stats:', transformedStats);
    return transformedStats;
  } catch (error) {
    console.error('Error fetching admin stats:', error);
    throw error;
  }
}

// Credit Package Management
export async function getCreditPackages() {
  try {
    const { data, error } = await supabase
      .from('credit_packages')
      .select('*')
      .order('credits', { ascending: true });

    if (error) throw error;
    
    // Log the packages data for debugging
    console.log('Credit packages from database:', data);
    
    return data;
  } catch (error) {
    console.error('Error fetching credit packages:', error);
    throw error;
  }
}

export async function createCreditPackage(packageData: Omit<CreditPackage, 'id' | 'created_at'>) {
  try {
    const { data, error } = await supabase
      .from('credit_packages')
      .insert([packageData])
      .select()
      .single();

    if (error) throw error;
    return data;
  } catch (error) {
    console.error('Error creating credit package:', error);
    throw error;
  }
}

export async function updateCreditPackage(
  id: string,
  packageData: Partial<Omit<CreditPackage, 'id' | 'created_at'>>
) {
  try {
    const { data, error } = await supabase
      .from('credit_packages')
      .update(packageData)
      .eq('id', id)
      .select()
      .single();

    if (error) throw error;
    return data;
  } catch (error) {
    console.error('Error updating credit package:', error);
    throw error;
  }
}

// Payment Gateway Management
export async function getPaymentGateways() {
  try {
    const { data, error } = await supabase
      .from('payment_gateways')
      .select('*')
      .order('created_at', { ascending: false });

    if (error) throw error;
    return data;
  } catch (error) {
    console.error('Error fetching payment gateways:', error);
    throw error;
  }
}

export async function updatePaymentGateway(
  id: string,
  config: Partial<Omit<PaymentGateway, 'id' | 'created_at' | 'updated_at'>>
) {
  try {
    const { data, error } = await supabase
      .from('payment_gateways')
      .update(config)
      .eq('id', id)
      .select()
      .single();

    if (error) throw error;
    return data;
  } catch (error) {
    console.error('Error updating payment gateway:', error);
    throw error;
  }
}

export async function toggleGatewayStatus(id: string, isActive: boolean) {
  try {
    const { error } = await supabase
      .from('payment_gateways')
      .update({ is_active: isActive })
      .eq('id', id);

    if (error) throw error;
    return true;
  } catch (error) {
    console.error('Error toggling gateway status:', error);
    throw error;
  }
}

// Add this new function for validation history
export async function getValidationHistory(userId?: string, page = 1, limit = 10) {
  try {
    const { data: { session } } = await supabase.auth.getSession();
    if (!session?.user) {
      throw new Error('No authenticated session');
    }

    // Check admin status
    const { data: adminUser, error: adminError } = await supabase
      .from('user_profiles')
      .select('is_admin')
      .eq('id', session.user.id)
      .single();

    if (adminError || !adminUser?.is_admin) {
      throw new Error('Unauthorized access');
    }

    const start = (page - 1) * limit;
    const end = start + limit - 1;

    // Build query
    let query = supabase
      .from('validation_history')
      .select(`
        id,
        user_id,
        total_emails,
        valid_emails,
        invalid_emails,
        risky_emails,
        provider,
        created_at,
        status,
        response_time,
        confidence,
        credits_used,
        file_name,
        error
      `, { count: 'exact' });

    // Add user filter if specified
    if (userId) {
      query = query.eq('user_id', userId);
    }

    // Get validation records
    const { data: validations, error, count } = await query
      .order('created_at', { ascending: false })
      .range(start, end);

    if (error) throw error;

    // Get user details for each validation
    const userIds = [...new Set(validations?.map(v => v.user_id) || [])];
    const { data: users } = await supabase
      .from('user_profiles')
      .select('id, email')
      .in('id', userIds);

    // Create user lookup map
    const userMap = new Map(users?.map(u => [u.id, u.email]) || []);

    // Transform validation records
    const enrichedValidations = validations?.map(validation => ({
      id: validation.id,
      userId: validation.user_id,
      userEmail: userMap.get(validation.user_id) || 'Unknown',
      totalEmails: validation.total_emails || 0,
      validCount: Array.isArray(validation.valid_emails) ? validation.valid_emails.length : 0,
      invalidCount: Array.isArray(validation.invalid_emails) ? validation.invalid_emails.length : 0,
      riskyCount: Array.isArray(validation.risky_emails) ? validation.risky_emails.length : 0,
      provider: validation.provider || 'Unknown',
      date: validation.created_at,
      status: validation.status,
      responseTime: validation.response_time,
      confidence: validation.confidence,
      creditsUsed: validation.credits_used,
      fileName: validation.file_name,
      error: validation.error
    })) || [];

    return {
      validations: enrichedValidations,
      total: count || 0,
      page,
      totalPages: Math.ceil((count || 0) / limit)
    };

  } catch (error) {
    console.error('Error fetching validation history:', error);
    throw error;
  }
}

// Add this function for getting a single validation record
export async function getValidationRecord(id: string) {
  try {
    const { data, error } = await supabase
      .from('email_validations')
      .select(`
        *,
        user_profiles:user_id (
          email
        )
      `)
      .eq('id', id)
      .single();

    if (error) throw error;
    return data;
  } catch (error) {
    console.error('Error fetching validation record:', error);
    throw error;
  }
}

// Add this function for toggling package status
export async function togglePackageStatus(id: string, isActive: boolean) {
  try {
    const { error } = await supabase
      .from('credit_packages')
      .update({ is_active: isActive })
      .eq('id', id);

    if (error) throw error;
    return true;
  } catch (error) {
    console.error('Error toggling package status:', error);
    throw error;
  }
}

// Add this function for getting package details
export async function getCreditPackageById(id: string) {
  try {
    const { data, error } = await supabase
      .from('credit_packages')
      .select('*')
      .eq('id', id)
      .single();

    if (error) throw error;
    return data;
  } catch (error) {
    console.error('Error fetching credit package:', error);
    throw error;
  }
}

// Add this function for deleting packages
export async function deleteCreditPackage(id: string) {
  try {
    const { error } = await supabase
      .from('credit_packages')
      .delete()
      .eq('id', id);

    if (error) throw error;
    return true;
  } catch (error) {
    console.error('Error deleting credit package:', error);
    throw error;
  }
}

// Add this function for getting user details
export async function getUserDetails(userId: string) {
  try {
    const { data, error } = await supabase
      .from('user_profiles')
      .select(`
        *,
        email_validations (
          count
        )
      `)
      .eq('id', userId)
      .single();

    if (error) throw error;
    return data;
  } catch (error) {
    console.error('Error fetching user details:', error);
    throw error;
  }
}

// Add this function for updating user profile
export async function updateUserProfile(userId: string, data: Partial<UserProfile>) {
  try {
    const { error } = await supabase
      .from('user_profiles')
      .update(data)
      .eq('id', userId);

    if (error) throw error;
    return true;
  } catch (error) {
    console.error('Error updating user profile:', error);
    throw error;
  }
}

// Add this function for getting admin role
export async function checkAdminRole(userId: string) {
  try {
    const { data, error } = await supabase
      .from('user_profiles')
      .select('is_admin')
      .eq('id', userId)
      .single();

    if (error) throw error;
    return data?.is_admin || false;
  } catch (error) {
    console.error('Error checking admin role:', error);
    return false;
  }
}

// Add this debug function
export async function debugAdminAccess() {
  try {
    const { data: { session } } = await supabase.auth.getSession();
    console.log('Debug: Current session:', {
      id: session?.user?.id,
      email: session?.user?.email,
      hasSession: !!session
    });

    if (!session?.user) {
      console.log('Debug: No session found');
      return null;
    }

    // Check admin status from user_profiles
    const { data: adminUser, error: adminError } = await supabase
      .from('user_profiles')
      .select('is_admin')
      .eq('id', session.user.id)
      .single();

    console.log('Debug: Admin check result:', {
      adminUser,
      error: adminError?.message
    });

    if (adminError) {
      console.error('Debug: Admin check error:', adminError);
      return null;
    }

    // Test user profiles access
    const { data: userProfiles, error: userError } = await supabase
      .from('user_profiles')
      .select('*')
      .limit(1);

    console.log('Debug: User profiles result:', {
      profiles: userProfiles,
      error: userError?.message
    });

    return {
      session,
      adminUser,
      userProfiles,
      userError
    };
  } catch (error) {
    console.error('Debug: Access error:', error);
    return null;
  }
}

// Add helper function to check admin permissions
export async function checkAdminPermissions() {
  try {
    const { data: { session } } = await supabase.auth.getSession();
    if (!session?.user?.id) return false;

    const { data: adminUser } = await supabase
      .from('user_profiles')
      .select('is_admin')
      .eq('id', session.user.id)
      .single();

    return adminUser?.is_admin || false;
  } catch (error) {
    console.error('Error checking admin permissions:', error);
    return false;
  }
}

// Update the function to use the correct parameter names
export async function updateValidationStats(
  validationId: string,
  validCount: number,
  invalidCount: number,
  riskyCount: number
) {
  try {
    const { data, error } = await supabase.rpc('update_validation_stats', {
      p_validation_id: validationId,
      p_valid_count: validCount,
      p_invalid_count: invalidCount,
      p_risky_count: riskyCount
    });

    if (error) throw error;
    return data;
  } catch (error) {
    console.error('Error updating validation stats:', error);
    throw error;
  }
}

// Add this function to update Lemonsqueezy variant IDs
export async function updatePackageVariantIds() {
  const packageVariants = [
    // Using the actual product UUIDs from Lemonsqueezy store
    { name: 'Starter Package', credits: 1000, lemon_variant_id: '87b77a89-1b75-4332-bed4-a02fcf33bb7e' },
    { name: 'Professional Package', credits: 5000, lemon_variant_id: 'f713e8f0-6712-4bbe-a2d9-14ada4d56d82' },
    { name: 'Enterprise Package', credits: 20000, lemon_variant_id: '89ef477e-fbdf-4297-8d16-8e32cd638224' }
  ];

  try {
    for (const pkg of packageVariants) {
      const { error } = await supabase
        .from('credit_packages')
        .update({ lemon_variant_id: pkg.lemon_variant_id })
        .match({ name: pkg.name, credits: pkg.credits });

      if (error) throw error;
    }
    console.log('Successfully updated package variant IDs');
  } catch (error) {
    console.error('Error updating package variant IDs:', error);
    throw error;
  }
}

// Add this function to check and refresh session if needed
export async function ensureAdminSession() {
  try {
    const { data: { session } } = await supabase.auth.getSession();
    if (!session) {
      throw new Error('No session found');
    }

    // Check if session needs refresh
    const expiryTime = localStorage.getItem('admin-session-expiry');
    if (expiryTime && Date.now() > parseInt(expiryTime)) {
      const { data: { session: newSession }, error } = await supabase.auth.refreshSession();
      if (error || !newSession) {
        throw new Error('Failed to refresh session');
      }
      // Update expiry
      localStorage.setItem('admin-session-expiry', 
        (Date.now() + 24 * 60 * 60 * 1000).toString()
      );
    }

    return true;
  } catch (error) {
    console.error('Session check error:', error);
    return false;
  }
}

// Add this function for getting validation stats
export async function getValidationStats(userId?: string): Promise<ValidationStats> {
  try {
    let query = supabase
      .from('validation_stats')
      .select(`
        total_emails,
        valid_emails,
        invalid_emails,
        risky_emails
      `);

    if (userId) {
      query = query.eq('user_id', userId);
    }

    const { data, error } = await query.single();

    if (error) throw error;
    if (!data) throw new Error('No stats data returned');

    return {
      total: data.total_emails,
      valid: data.valid_emails,
      invalid: data.invalid_emails,
      risky: data.risky_emails
    };
  } catch (error) {
    console.error('Error fetching validation stats:', error);
    throw new Error('Failed to fetch validation stats');
  }
}