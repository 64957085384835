import React from 'react';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { Button } from '@/components/ui/button';
import { Copy } from 'lucide-react';
import { toast } from '@/components/ui/use-toast';

interface CodeBlockProps {
  language: string;
  code: string;
}

function CodeBlock({ language, code }: CodeBlockProps) {
  const copyCode = () => {
    navigator.clipboard.writeText(code);
    toast({
      title: "Copied to clipboard",
      description: "The code has been copied to your clipboard.",
    });
  };

  return (
    <div className="relative">
      <pre className="rounded-lg bg-muted p-4 overflow-x-auto">
        <code className={`language-${language}`}>{code}</code>
      </pre>
      <Button
        variant="ghost"
        size="sm"
        className="absolute top-2 right-2"
        onClick={copyCode}
      >
        <Copy className="h-4 w-4" />
      </Button>
    </div>
  );
}

interface APIGuideProps {
  apiKey: string;
}

export function APIGuide({ apiKey }: APIGuideProps) {
  const curlExample = `curl -X POST \\
  https://app.emailbounce.org/api/v1/validate-email \\
  -H 'Authorization: Bearer ${apiKey}' \\
  -H 'Content-Type: application/json' \\
  -d '{
    "email": "test@example.com"
  }'`;

  const nodeExample = `const response = await fetch('https://app.emailbounce.org/api/v1/validate-email', {
  method: 'POST',
  headers: {
    'Authorization': 'Bearer ${apiKey}',
    'Content-Type': 'application/json',
  },
  body: JSON.stringify({
    email: 'test@example.com'
  }),
});

const result = await response.json();`;

  const pythonExample = `import requests

response = requests.post(
    'https://app.emailbounce.org/api/v1/validate-email',
    headers={
        'Authorization': 'Bearer ${apiKey}',
        'Content-Type': 'application/json',
    },
    json={
        'email': 'test@example.com'
    },
)

result = response.json()`;

  const bulkExample = `curl -X POST \\
  https://app.emailbounce.org/api/v1/validate-bulk \\
  -H 'Authorization: Bearer ${apiKey}' \\
  -H 'Content-Type: application/json' \\
  -d '{
    "emails": ["test1@example.com", "test2@example.com"],
    "fileName": "my_list.csv",
    "skipDuplicates": true,
    "validateDisposable": true,
    "validateRoleAccounts": true
  }'`;

  return (
    <div className="space-y-4 sm:space-y-6">
      <div className="space-y-1.5 sm:space-y-2">
        <h1 className="text-2xl sm:text-3xl font-bold tracking-tight">API Documentation</h1>
        <p className="text-sm sm:text-base text-muted-foreground">
          Integrate email validation into your application using our REST API.
        </p>
      </div>

      <Card>
        <CardHeader className="space-y-1.5">
          <CardTitle>Authentication</CardTitle>
          <CardDescription>
            All API requests require authentication using your API key in the Authorization header.
          </CardDescription>
        </CardHeader>
        <CardContent>
          <div className="space-y-3">
            <p className="text-sm">Your API Key:</p>
            <div className="flex items-center gap-2">
              <code className="flex-1 relative rounded bg-muted px-3 py-2 font-mono text-sm">
                {apiKey}
              </code>
              <Button
                variant="ghost"
                size="sm"
                onClick={() => {
                  navigator.clipboard.writeText(apiKey);
                  toast({
                    title: "API Key copied",
                    description: "Your API key has been copied to the clipboard.",
                  });
                }}
              >
                <Copy className="h-4 w-4" />
              </Button>
            </div>
          </div>
        </CardContent>
      </Card>

      <Card>
        <CardHeader className="space-y-1.5">
          <CardTitle>Endpoints</CardTitle>
          <CardDescription>
            Available API endpoints and their usage.
          </CardDescription>
        </CardHeader>
        <CardContent>
          <Tabs defaultValue="single" className="space-y-4">
            <TabsList className="w-full sm:w-auto">
              <TabsTrigger value="single">Single Email</TabsTrigger>
              <TabsTrigger value="bulk">Bulk Validation</TabsTrigger>
              <TabsTrigger value="history">History</TabsTrigger>
            </TabsList>
            <TabsContent value="single">
              <div className="space-y-4">
                <h3 className="font-medium">POST /api/v1/validate-email</h3>
                <p className="text-sm text-muted-foreground">
                  Validate a single email address with enhanced validation features.
                </p>
                <Tabs defaultValue="curl" className="space-y-4">
                  <TabsList className="w-full sm:w-auto">
                    <TabsTrigger value="curl">cURL</TabsTrigger>
                    <TabsTrigger value="node">Node.js</TabsTrigger>
                    <TabsTrigger value="python">Python</TabsTrigger>
                  </TabsList>
                  <TabsContent value="curl">
                    <CodeBlock language="bash" code={curlExample} />
                  </TabsContent>
                  <TabsContent value="node">
                    <CodeBlock language="javascript" code={nodeExample} />
                  </TabsContent>
                  <TabsContent value="python">
                    <CodeBlock language="python" code={pythonExample} />
                  </TabsContent>
                </Tabs>
              </div>
            </TabsContent>
            <TabsContent value="bulk">
              <div className="space-y-4">
                <h3 className="font-medium">POST /api/v1/validate-bulk</h3>
                <p className="text-sm text-muted-foreground">
                  Validate multiple email addresses with advanced options.
                </p>
                <div className="space-y-4">
                  <CodeBlock language="bash" code={bulkExample} />
                  <div className="space-y-2">
                    <h4 className="font-medium">Options</h4>
                    <ul className="list-disc list-inside text-sm space-y-1">
                      <li><code>fileName</code>: Optional name for tracking the validation batch</li>
                      <li><code>skipDuplicates</code>: Skip duplicate email addresses (default: true)</li>
                      <li><code>validateDisposable</code>: Check for disposable email providers (default: true)</li>
                      <li><code>validateRoleAccounts</code>: Check for role-based accounts (default: true)</li>
                    </ul>
                  </div>
                </div>
              </div>
            </TabsContent>
            <TabsContent value="history">
              <div className="space-y-4">
                <h3 className="font-medium">GET /api/v1/validation-history</h3>
                <p className="text-sm text-muted-foreground">
                  Retrieve your validation history with pagination.
                </p>
                <CodeBlock
                  language="bash"
                  code={`curl \\
  https://app.emailbounce.org/api/v1/validation-history \\
  -H 'Authorization: Bearer ${apiKey}' \\
  -H 'X-Page: 1' \\
  -H 'X-Limit: 10'`}
                />
              </div>
            </TabsContent>
          </Tabs>
        </CardContent>
      </Card>

      <Card>
        <CardHeader className="space-y-1.5">
          <CardTitle>Response Format</CardTitle>
          <CardDescription>
            Example responses from the API.
          </CardDescription>
        </CardHeader>
        <CardContent>
          <Tabs defaultValue="single" className="space-y-4">
            <TabsList>
              <TabsTrigger value="single">Single Email</TabsTrigger>
              <TabsTrigger value="bulk">Bulk Response</TabsTrigger>
            </TabsList>
            <TabsContent value="single">
              <CodeBlock
                language="json"
                code={`{
  "email": "test@example.com",
  "is_valid": true,
  "is_disposable": false,
  "is_role_account": false,
  "is_free_provider": true,
  "did_you_mean": null,
  "domain": "example.com",
  "domain_age_days": 10950,
  "smtp_check": true,
  "mx_records": true,
  "format_valid": true,
  "status": "valid",
  "validation_id": "val_123456789",
  "credits_used": 1,
  "confidence_score": 0.95,
  "provider": "primary",
  "validation_time": 1234,
  "issues": []
}`}
              />
            </TabsContent>
            <TabsContent value="bulk">
              <CodeBlock
                language="json"
                code={`{
  "validation_id": "val_bulk_123456789",
  "status": "completed",
  "total_processed": 2,
  "total_valid": 1,
  "total_invalid": 1,
  "total_risky": 0,
  "credits_used": 2,
  "file_name": "my_list.csv",
  "start_time": 1624294800000,
  "end_time": 1624294801000,
  "processing_time": 1000,
  "results": [
    {
      "email": "valid@example.com",
      "status": "valid",
      "is_valid": true,
      // ... other fields same as single validation
    },
    {
      "email": "invalid@example.com",
      "status": "invalid",
      "is_valid": false,
      // ... other fields same as single validation
    }
  ]
}`}
              />
            </TabsContent>
          </Tabs>
        </CardContent>
      </Card>

      <Card>
        <CardHeader className="space-y-1.5">
          <CardTitle>Rate Limits & Credits</CardTitle>
          <CardDescription>
            Understanding API usage and limits.
          </CardDescription>
        </CardHeader>
        <CardContent>
          <div className="space-y-4">
            <p className="text-sm">Each validation consumes one credit. You can check your remaining credits:</p>
            <CodeBlock
              language="bash"
              code={`curl \\
  https://app.emailbounce.org/api/v1/credits \\
  -H 'Authorization: Bearer ${apiKey}'`}
            />
            <div className="text-sm space-y-2">
              <p>Rate limits:</p>
              <ul className="list-disc list-inside space-y-1">
                <li>Single validation: 10 requests per second</li>
                <li>Bulk validation: 1,000 emails per request</li>
                <li>Maximum file size: 10MB</li>
              </ul>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}
