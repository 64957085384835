import React, { useState, useEffect, ChangeEvent, FormEvent } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Upload, FileText, AlertCircle, Loader2, CheckCircle, XCircle, AlertTriangle, CreditCard } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Progress } from '@/components/ui/progress';
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
  CardFooter,
} from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Separator } from '@/components/ui/separator';
import { useToast } from '@/hooks/use-toast';
import { validateEmails, validateSingleEmail, getUserCredits } from '@/lib/api';
import { ValidationResult } from '@/lib/types';
import { ValidationReport } from './ValidationReport';
import { v4 as uuidv4 } from 'uuid';
import { deductValidationCredits } from '@/lib/validation';

const readEmailsFromFile = async (file: File): Promise<string[]> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      try {
        const content = event.target?.result as string;
        const emails = content
          .split(/[\r\n,]+/)
          .map(email => email.trim())
          .filter(email => email && email.includes('@'));
        resolve(emails);
      } catch (error) {
        reject(error);
      }
    };
    reader.onerror = (error) => reject(error);
    reader.readAsText(file);
  });
};

export function UploadForm() {
  const navigate = useNavigate();
  const location = useLocation();
  const validationId = location.state?.validationId || uuidv4();
  const { toast } = useToast();
  const [file, setFile] = useState<File | null>(null);
  const [singleEmail, setSingleEmail] = useState('');
  const [progress, setProgress] = useState(0);
  const [isProcessing, setIsProcessing] = useState(false);
  const [validationResult, setValidationResult] = useState<ValidationResult | null>(null);
  const [emailCount, setEmailCount] = useState(0);
  const [credits, setCredits] = useState(0);

  useEffect(() => {
    loadCredits();
  }, []);

  const loadCredits = async () => {
    try {
      const userCredits = await getUserCredits();
      setCredits(userCredits);
    } catch (error) {
      console.error('Failed to load credits:', error);
    }
  };

  const checkCredits = async (required: number) => {
    const available = await getUserCredits();
    if (available < required) {
      toast({
        title: "Insufficient Credits",
        description: `You need ${required} credits but only have ${available}. Please purchase more credits.`,
        variant: "destructive",
      });
      navigate('/credits');
      return false;
    }
    return true;
  };

  const countEmailsInFile = (file: File): Promise<number> => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const content = event.target?.result as string;
        const emails = content
          .split(/[\r\n,]+/)
          .map(email => email.trim())
          .filter(email => email && email.includes('@'));
        resolve(emails.length);
      };
      reader.readAsText(file);
    });
  };

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0];
    if (!selectedFile) return;

    // Check file extension
    const fileExtension = selectedFile.name.split('.').pop()?.toLowerCase();
    const allowedExtensions = ['csv', 'txt', 'xls', 'xlsx'];

    if (!fileExtension || !allowedExtensions.includes(fileExtension)) {
      toast({
        title: "Invalid file type",
        description: "Please upload a CSV, TXT, or Excel file",
        variant: "destructive",
      });
      return;
    }

    // Process file based on type
    try {
      setFile(selectedFile);
      setProgress(0);
      setValidationResult(null);

      let emails: string[] = [];

      if (fileExtension === 'txt' || fileExtension === 'csv') {
        // Handle text and CSV files
        const text = await readFileAsText(selectedFile);
        emails = text
          .split(/[\r\n,]+/) // Split by newline or comma
          .map(email => email.trim())
          .filter(email => email && email.includes('@'));
      } else {
        // Handle Excel files (existing logic)
        // ... your existing Excel handling code ...
      }

      setEmailCount(emails.length);
    } catch (error) {
      console.error('File processing error:', error);
      toast({
        title: "Error",
        description: "Failed to process file",
        variant: "destructive",
      });
    }
  };

  // Helper function to read file as text
  const readFileAsText = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => resolve(e.target?.result as string);
      reader.onerror = (e) => reject(e);
      reader.readAsText(file);
    });
  };

  const refreshCredits = async () => {
    try {
      const userCredits = await getUserCredits();
      setCredits(userCredits);
      localStorage.setItem('userCredits', userCredits.toString());
    } catch (error) {
      console.error('Failed to refresh credits:', error);
    }
  };

  const handleSingleEmailValidation = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!singleEmail) return;
    if (!await checkCredits(1)) return;
    setFile(null); // Clear any existing file
    await handleValidation([singleEmail], true);
  };

  const handleBulkValidation = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!file) return;
    
    try {
      setIsProcessing(true);
      const emails = await readEmailsFromFile(file);
      
      if (emails.length === 0) {
        toast({
          title: "No valid emails found",
          description: "The file contains no valid email addresses.",
          variant: "destructive",
        });
        return;
      }
      
      // Check credits before validation
      if (!await checkCredits(emails.length)) return;
      await handleValidation(emails, false);

    } catch (error) {
      console.error('Error processing file:', error);
      toast({
        title: "Error",
        description: error instanceof Error ? error.message : "Failed to process emails",
        variant: "destructive",
      });
    } finally {
      setIsProcessing(false);
    }
  };

  const handleValidation = async (emails: string[], isSingleEmail: boolean = false) => {
    try {
      setIsProcessing(true);
      
      // Get file name for display
      const displayName = isSingleEmail 
        ? emails[0] // Use email address for single validation
        : file?.name || `Bulk Validation ${new Date().toLocaleString()}`; // Use file name or friendly timestamp
      
      console.log('Starting validation with:', {
        emailCount: emails.length,
        isSingleEmail,
        displayName,
        fileName: file?.name
      });
      
      // Validate emails with the display name
      const result = await validateEmails(emails, displayName);
      
      // Ensure we have valid result before proceeding
      if (!result || !result.id) {
        throw new Error('Invalid validation result received');
      }
      
      // Credits are deducted in the Edge Function, just refresh the balance
      await refreshCredits();

      // Navigate to results page with validation data
      navigate('/validation/results', {
        state: { 
          validationResult: {
            ...result,
            metadata: {
              fileName: displayName
            }
          }
        }
      });

      toast({
        title: "Validation Complete",
        description: `Successfully validated ${emails.length} emails.`,
      });
    } catch (error) {
      console.error('Validation error:', error);
      toast({
        title: "Validation Failed",
        description: error instanceof Error ? error.message : "There was a problem validating the emails.",
        variant: "destructive",
      });
    } finally {
      setIsProcessing(false);
    }
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsProcessing(true);

    try {
      // ... existing processing logic ...

      // Use the validationId when saving results
      const validationResult = {
        id: validationId,
        date: new Date(),
        fileName: file?.name ?? 'Unknown',
        totalEmails: emailCount,
        valid: 0,
        invalid: 0,
      };

      // ... rest of the submission logic ...
    } catch (error) {
      // ... error handling ...
    }
  };

  if (validationResult) {
    return <ValidationReport result={validationResult} />;
  }

  return (
 
    <div className="space-y-6">
      <h2 className="text-2xl sm:text-3xl font-bold tracking-tight">Start Validation</h2>
      <Card>
        <CardHeader>
          <CardTitle className="text-lg sm:text-xl">Single Email Validation</CardTitle>
          <CardDescription className="text-sm">
            Validate a single email address instantly
          </CardDescription>
        </CardHeader>
        <CardContent>
          <form onSubmit={handleSingleEmailValidation} className="space-y-4">
            <div className="flex flex-col sm:flex-row items-stretch sm:items-end gap-4">
              <div className="flex-1">
                <Label htmlFor="email">Email Address</Label>
                <Input
                  id="email"
                  type="email"
                  placeholder="example@domain.com"
                  value={singleEmail}
                  onChange={(e) => setSingleEmail(e.target.value)}
                  required
                  className="mt-1.5"
                />
              </div>
              <Button 
                type="submit" 
                disabled={isProcessing}
                className="w-full sm:w-auto"
              >
                {isProcessing ? (
                  <>
                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                    Validating
                  </>
                ) : (
                  'Validate'
                )}
              </Button>
            </div>
          </form>
        </CardContent>
      </Card>

      <Card className="hover:shadow-lg transition-shadow">
        <CardHeader>
          <CardTitle className="text-lg sm:text-xl">Bulk Email Validation</CardTitle>
          <CardDescription className="text-sm">
            Upload your CSV or Excel file containing email addresses for validation
          </CardDescription>
        </CardHeader>
        <CardContent>
          <form onSubmit={handleBulkValidation} className="space-y-6">
            <div className="border-2 border-dashed border-muted rounded-lg p-4 sm:p-8 transition-colors hover:border-primary/50">
              <input
                type="file"
                id="file-upload"
                className="hidden"
                onChange={handleFileChange}
                accept=".csv,.txt,.xls,.xlsx"
              />
              <label
                htmlFor="file-upload"
                className="cursor-pointer flex flex-col items-center justify-center"
              >
                <Upload className="h-8 w-8 sm:h-10 sm:w-10 text-muted-foreground mb-4" />
                <span className="text-sm sm:text-base font-medium text-center">
                  {file ? (
                    <div className="flex items-center space-x-2">
                      <FileText className="h-4 w-4" />
                      <span className="truncate max-w-[200px] sm:max-w-none">{file.name}</span>
                    </div>
                  ) : (
                    'Drop your file here or click to browse'
                  )}
                </span>
                <span className="text-xs sm:text-sm text-muted-foreground mt-2 text-center">
                  Supports CSV, TXT, Excel, and text files
                </span>
              </label>
            </div>

            {file && emailCount > 0 && (
              <>
                <Alert>
                  <AlertCircle className="h-4 w-4" />
                  <AlertTitle className="text-sm sm:text-base">Credits Required</AlertTitle>
                  <AlertDescription className="text-sm">
                    This file contains {emailCount} emails. You'll need {emailCount} credits to
                    process it. You currently have {credits} credits available.
                  </AlertDescription>
                </Alert>

                <div className="space-y-2">
                  {isProcessing && (
                    <div className="space-y-4">
                      <Progress value={progress} className="w-full" />
                      <div className="flex items-center justify-center space-x-2">
                        <Loader2 className="h-4 w-4 animate-spin" />
                        <p className="text-sm text-muted-foreground">
                          Processing... {progress}%
                        </p>
                      </div>
                      <div className="text-center text-sm text-muted-foreground">
                        Validating email addresses, please wait...
                      </div>
                    </div>
                  )}
                </div>

                <Button
                  type="submit"
                  className="w-full py-6 text-base"
                  disabled={isProcessing}
                  size="lg"
                >
                  {isProcessing ? (
                    <>
                      <Loader2 className="mr-2 h-5 w-5 animate-spin" />
                      Processing
                    </>
                  ) : (
                    'Start Validation'
                  )}
                </Button>
              </>
            )}
          </form>
        </CardContent>
      </Card>
    </div>
  );
}