import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import { Upload } from 'lucide-react';
import { ValidationReport } from './ValidationReport';
import { ExportEmails } from '@/components/email-marketing/ExportEmails';

export function ValidationResults() {
  const location = useLocation();
  const navigate = useNavigate();
  const validationResult = location.state?.validationResult;

  console.log('ValidationResults - Location State:', location.state);
  console.log('ValidationResults - Validation Result:', validationResult);

  if (!validationResult) {
    return (
      <div className="flex flex-col items-center justify-center min-h-[400px] space-y-4">
        <p className="text-muted-foreground">No validation results found</p>
        <Button 
          variant="default" 
          onClick={() => navigate('/validate')}
          className="w-full sm:w-auto"
        >
          <Upload className="mr-2 h-4 w-4" />
          New Validation
        </Button>
      </div>
    );
  }

  // Extract validation ID from the result
  const validationId = validationResult.id || validationResult.validationId;
  const { status, totalEmails, valid: validEmails = [] } = validationResult;
  
  console.log('ValidationResults - Extracted Data:', {
    validationId,
    status,
    totalEmails,
    validEmailsCount: validEmails.length
  });

  return (
    <div className="space-y-6">
      <ValidationReport result={validationResult} />
    </div>
  );
}
