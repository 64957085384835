import disposableDomains from 'disposable-email-domains';
import deepEmailValidator from 'deep-email-validator';
import emailExistence from 'email-existence';
import Mailcheck from 'mailcheck';
import { chunk } from 'lodash';

// Core patterns that worked well
const ROLE_BASED_PREFIXES = new Set([
  'admin', 'info', 'support', 'sales', 'contact',
  'help', 'webmaster', 'postmaster', 'marketing',
  'no-reply', 'noreply', 'abuse', 'bounce', 'billing',
  'careers', 'dev', 'development', 'hello', 'hostmaster',
  'jobs', 'list', 'office', 'recruitment', 'root',
  'security', 'spam', 'sysadmin', 'tech', 'webadmin'
]);

const SPAM_TRAP_PATTERNS = [
  /^spam.*trap/i,
  /^trap.*spam/i,
  /^honey.*pot/i,
  /^pot.*honey/i,
  /^spamtrap/i,
  /^blackhole/i,
  /^drop.*box/i,
  /^abuse.*detect/i
];

const BLACKLISTED_DOMAINS = new Set([
  'tempmail.com', 'throwawaymail.com',
  'mailinator.com', '10minutemail.com',
  'guerrillamail.com', 'sharklasers.com',
  'yopmail.com', 'tempinbox.com',
  'fakeinbox.com', 'trash-mail.com'
]);

// Email validation constants
const MAX_EMAIL_LENGTH = 254;
const MIN_LOCAL_PART_LENGTH = 1;
const MAX_LOCAL_PART_LENGTH = 64;
const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

// Core validation patterns
const SUSPICIOUS_PATTERNS = {
  REPEATED_CHARS: /(.)\1{4,}/,  // e.g., 'aaaaa'
  SUSPICIOUS_DOTS: /\.{2,}|(?:\.[^@]*){4,}/,  // Multiple dots
  SPECIAL_CHARS: /[._%+-]{3,}/,  // Too many special chars
  NUMERIC_START: /^\d+/,  // Starts with numbers
  LENGTH_CHECK: /^.{1,4}@|^.{25,}@/  // Too short/long local part
};

// Interfaces
export interface ValidationResult {
  isValid: boolean;
  reason?: string;
  status: 'valid' | 'invalid' | 'risky';
  confidence_score?: number;
  provider?: string;
  validation_time?: number;
  issues?: string[];
  email: string;
  validation_id?: string;
}

export interface BatchValidationResult {
  valid: string[];
  invalid: string[];
  risky: string[];
  disposable: string[];
  spamTraps: string[];
  totalEmails: number;
}

export interface ValidationProgress {
  processed: number;
  total: number;
  currentBatch: number;
  totalBatches: number;
}

// Core validation function
const validateEmailFormat = (email: string): ValidationResult => {
  if (!email || typeof email !== 'string') {
    return { isValid: false, reason: 'Email cannot be empty', status: 'invalid', email };
  }

  const [localPart, domain] = email.toLowerCase().split('@');

  // Basic format checks
  if (!EMAIL_REGEX.test(email)) {
    return { isValid: false, reason: 'Invalid email format', status: 'invalid', email };
  }

  // Length checks
  if (email.length > MAX_EMAIL_LENGTH) {
    return { isValid: false, reason: 'Email too long', status: 'invalid', email };
  }

  if (localPart.length < MIN_LOCAL_PART_LENGTH || localPart.length > MAX_LOCAL_PART_LENGTH) {
    return { isValid: false, reason: 'Invalid local part length', status: 'invalid', email };
  }

  // Pattern checks
  if (SUSPICIOUS_PATTERNS.REPEATED_CHARS.test(localPart)) {
    return { isValid: false, reason: 'Too many repeated characters', status: 'invalid', email };
  }

  if (SUSPICIOUS_PATTERNS.SUSPICIOUS_DOTS.test(localPart)) {
    return { isValid: false, reason: 'Invalid dot pattern', status: 'invalid', email };
  }

  return { isValid: true, status: 'valid', email };
};

// Main validation functions
export async function validateEmail(email: string): Promise<ValidationResult> {
  try {
    const response = await fetch('https://app.emailbounce.org/api/v1/validate-email', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email })
    });

    if (!response.ok) {
      throw new Error(`API error: ${response.statusText}`);
    }

    const result = await response.json();
    return {
      isValid: result.valid,
      status: result.valid ? 'valid' : 'invalid',
      reason: result.reason,
      email,
      confidence_score: result.confidence || 0
    };
  } catch (error) {
    console.error('Validation error:', error);
    return {
      isValid: false,
      status: 'invalid',
      reason: error instanceof Error ? error.message : 'Validation failed',
      email
    };
  }
}

export async function validateEmailBatch(
  emails: string[],
  onProgress?: (progress: ValidationProgress) => void
): Promise<BatchValidationResult> {
  try {
    // Update progress
    if (onProgress) {
      onProgress({
        processed: 0,
        total: emails.length,
        currentBatch: 1,
        totalBatches: 1
      });
    }

    const response = await fetch('https://app.emailbounce.org/api/v1/validate-bulk', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ emails })
    });

    if (!response.ok) {
      throw new Error(`API error: ${response.statusText}`);
    }

    const results = await response.json();

    // Update final progress
    if (onProgress) {
      onProgress({
        processed: emails.length,
        total: emails.length,
        currentBatch: 1,
        totalBatches: 1
      });
    }

    return {
      valid: results.valid || [],
      invalid: results.invalid || [],
      risky: results.risky || [],
      disposable: results.disposable || [],
      spamTraps: results.spamTraps || [],
      totalEmails: emails.length
    };
  } catch (error) {
    console.error('Batch validation error:', error);
    return {
      valid: [],
      invalid: emails,
      risky: [],
      disposable: [],
      spamTraps: [],
      totalEmails: emails.length
    };
  }
}

export async function validateEmailsWithRetry(
  emails: string[],
  maxRetries = 3,
  onProgress?: (progress: ValidationProgress) => void
): Promise<BatchValidationResult> {
  let retries = 0;
  let error: Error | null = null;

  while (retries < maxRetries) {
    try {
      return await validateEmailBatch(emails, onProgress);
    } catch (err) {
      error = err as Error;
      retries++;
      await new Promise(resolve => setTimeout(resolve, 1000 * retries));
    }
  }

  throw error || new Error('Max retries exceeded');
}