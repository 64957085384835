import { AdminTicketList } from '@/components/support/AdminTicketList';

export function AdminSupportPage() {
  return (
    <div className="space-y-4">
      <AdminTicketList />
    </div>
  );
}

export default AdminSupportPage;
