import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { Textarea } from '@/components/ui/textarea';
import { Badge } from '@/components/ui/badge';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { useToast } from '@/components/ui/use-toast';
import { SupportTicket, TicketResponse } from '@/lib/types/support';
import { addTicketResponse, getTicketDetails, updateTicket } from '@/lib/support';
import { formatDistanceToNow } from 'date-fns';
import { AlertCircle, ArrowLeft, Clock, Loader2, Send } from 'lucide-react';
import { cn } from '@/lib/utils';

const STATUS_CONFIG = {
  open: {
    color: 'bg-emerald-100 text-emerald-700 hover:bg-emerald-200',
    icon: AlertCircle,
    label: 'Open',
  },
  in_progress: {
    color: 'bg-blue-100 text-blue-700 hover:bg-blue-200',
    icon: Clock,
    label: 'In Progress',
  },
  resolved: {
    color: 'bg-gray-100 text-gray-700 hover:bg-gray-200',
    icon: AlertCircle,
    label: 'Resolved',
  },
  closed: {
    color: 'bg-red-100 text-red-700 hover:bg-red-200',
    icon: AlertCircle,
    label: 'Closed',
  },
};

const PRIORITY_CONFIG = {
  low: {
    color: 'bg-gray-100 text-gray-700',
    label: 'Low',
  },
  medium: {
    color: 'bg-yellow-100 text-yellow-700',
    label: 'Medium',
  },
  high: {
    color: 'bg-orange-100 text-orange-700',
    label: 'High',
  },
  urgent: {
    color: 'bg-red-100 text-red-700',
    label: 'Urgent',
  },
};

export function AdminTicketDetails() {
  const { ticketId } = useParams<{ ticketId: string }>();
  const navigate = useNavigate();
  const { toast } = useToast();
  const [ticket, setTicket] = useState<SupportTicket | null>(null);
  const [responses, setResponses] = useState<TicketResponse[]>([]);
  const [newResponse, setNewResponse] = useState('');
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [updatingStatus, setUpdatingStatus] = useState(false);

  useEffect(() => {
    if (ticketId) {
      loadTicketDetails();
    }
  }, [ticketId]);

  const loadTicketDetails = async () => {
    try {
      setLoading(true);
      const { ticket, responses } = await getTicketDetails(ticketId!);
      setTicket(ticket);
      setResponses(responses);
    } catch (error) {
      console.error('Failed to load ticket details:', error);
      toast({
        title: 'Error',
        description: 'Failed to load ticket details',
        variant: 'destructive',
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSubmitResponse = async () => {
    if (!newResponse.trim()) return;

    try {
      setSubmitting(true);
      await addTicketResponse({
        ticket_id: ticketId!,
        message: newResponse,
        is_admin_response: true,
      });
      setNewResponse('');
      toast({
        title: 'Response Added',
        description: 'Your response has been added successfully.',
      });
      await loadTicketDetails(); // Reload to get the new response
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to add response',
        variant: 'destructive',
      });
    } finally {
      setSubmitting(false);
    }
  };

  const handleStatusChange = async (newStatus: string) => {
    try {
      setUpdatingStatus(true);
      await updateTicket(ticketId!, { status: newStatus as any });
      setTicket((prev) => prev ? { ...prev, status: newStatus as any } : null);
      toast({
        title: 'Status Updated',
        description: 'Ticket status has been updated successfully.',
      });
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to update ticket status',
        variant: 'destructive',
      });
    } finally {
      setUpdatingStatus(false);
    }
  };

  const handlePriorityChange = async (newPriority: string) => {
    try {
      setUpdatingStatus(true);
      await updateTicket(ticketId!, { priority: newPriority as any });
      setTicket((prev) => prev ? { ...prev, priority: newPriority as any } : null);
      toast({
        title: 'Priority Updated',
        description: 'Ticket priority has been updated successfully.',
      });
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to update ticket priority',
        variant: 'destructive',
      });
    } finally {
      setUpdatingStatus(false);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center py-8">
        <Loader2 className="h-8 w-8 animate-spin" />
      </div>
    );
  }

  if (!ticket) {
    return (
      <div className="flex flex-col items-center justify-center py-8 space-y-4">
        <AlertCircle className="h-8 w-8 text-red-500" />
        <p className="text-lg font-medium">Ticket not found</p>
        <Button variant="outline" onClick={() => navigate('/admin/support/tickets')}>
          Back to Tickets
        </Button>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <Button
          variant="outline"
          onClick={() => navigate('/admin/support/tickets')}
          className="flex items-center gap-2"
        >
          <ArrowLeft className="h-4 w-4" />
          Back to Tickets
        </Button>
      </div>

      <Card>
        <CardHeader>
          <div className="flex justify-between items-start">
            <div className="space-y-1">
              <CardTitle>{ticket.subject}</CardTitle>
              <CardDescription>
                Created {formatDistanceToNow(new Date(ticket.created_at), { addSuffix: true })}
                {' by '}
                <span className="font-medium">{ticket.profiles?.email}</span>
              </CardDescription>
            </div>
          </div>
        </CardHeader>
        <CardContent>
          <div className="space-y-6">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              <div>
                <label className="text-sm font-medium">Status</label>
                <Select
                  value={ticket.status}
                  onValueChange={handleStatusChange}
                  disabled={updatingStatus}
                >
                  <SelectTrigger>
                    <SelectValue />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="open">Open</SelectItem>
                    <SelectItem value="in_progress">In Progress</SelectItem>
                    <SelectItem value="resolved">Resolved</SelectItem>
                    <SelectItem value="closed">Closed</SelectItem>
                  </SelectContent>
                </Select>
              </div>
              <div>
                <label className="text-sm font-medium">Priority</label>
                <Select
                  value={ticket.priority}
                  onValueChange={handlePriorityChange}
                  disabled={updatingStatus}
                >
                  <SelectTrigger>
                    <SelectValue />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="low">Low</SelectItem>
                    <SelectItem value="medium">Medium</SelectItem>
                    <SelectItem value="high">High</SelectItem>
                    <SelectItem value="urgent">Urgent</SelectItem>
                  </SelectContent>
                </Select>
              </div>
              <div>
                <label className="text-sm font-medium">Category</label>
                <p className="mt-2 text-sm">{ticket.category}</p>
              </div>
            </div>

            <div>
              <h3 className="font-medium mb-2">Description</h3>
              <p className="text-sm text-muted-foreground whitespace-pre-wrap">
                {ticket.description}
              </p>
            </div>

            <div className="space-y-4">
              <h3 className="font-medium">Responses</h3>
              <div className="space-y-4">
                {responses.map((response) => (
                  <Card key={response.id}>
                    <CardHeader>
                      <div className="flex justify-between items-center">
                        <div className="space-y-1">
                          <CardTitle className="text-sm font-medium">
                            {response.is_admin_response ? 'Admin Response' : ticket.profiles?.email}
                          </CardTitle>
                          <CardDescription>
                            {formatDistanceToNow(new Date(response.created_at), {
                              addSuffix: true,
                            })}
                          </CardDescription>
                        </div>
                        {response.is_admin_response && (
                          <Badge variant="secondary" className="bg-blue-100 text-blue-700">
                            Admin
                          </Badge>
                        )}
                      </div>
                    </CardHeader>
                    <CardContent>
                      <p className="text-sm whitespace-pre-wrap">{response.message}</p>
                    </CardContent>
                  </Card>
                ))}
              </div>

              <div className="space-y-4">
                <Textarea
                  placeholder="Type your response..."
                  value={newResponse}
                  onChange={(e) => setNewResponse(e.target.value)}
                  rows={4}
                />
                <Button
                  onClick={handleSubmitResponse}
                  disabled={submitting || !newResponse.trim()}
                  className="flex items-center gap-2"
                >
                  {submitting ? (
                    <Loader2 className="h-4 w-4 animate-spin" />
                  ) : (
                    <Send className="h-4 w-4" />
                  )}
                  {submitting ? 'Sending...' : 'Send Response'}
                </Button>
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}
