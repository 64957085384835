import { useState, useEffect } from 'react';
import { supabase } from '@/lib/supabase';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { useToast } from '@/components/ui/use-toast';
import { Loader2, Download } from 'lucide-react';
import { Badge } from '@/components/ui/badge';
import { format } from 'date-fns';
import { Input } from '@/components/ui/input';

interface ValidationRecord {
  id: string;
  user_id: string;
  email: string | null;
  total_emails: number;
  valid_emails: string[];
  invalid_emails: string[];
  risky_emails: string[];
  disposable_emails: string[];
  spam_trap_emails: string[];
  file_name: string;
  credits_used: number;
  status: string;
  confidence: number;
  provider: string | null;
  issues: any;
  response_time: number | null;
  validation_result: string | null;
  created_at: string;
  updated_at: string;
  user_email: string;
}

interface ValidationWithUser {
  id: string;
  user_id: string;
  email: string | null;
  total_emails: number;
  valid_emails: string[];
  invalid_emails: string[];
  risky_emails: string[];
  disposable_emails: string[];
  spam_trap_emails: string[];
  file_name: string;
  credits_used: number;
  status: string;
  confidence: number;
  provider: string | null;
  issues: any;
  response_time: number | null;
  validation_result: string | null;
  created_at: string;
  updated_at: string;
  user: { email: string } | null;
}

const PAGE_SIZE = 20;

export function AdminValidationHistory() {
  const [validations, setValidations] = useState<ValidationRecord[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [search, setSearch] = useState('');
  const { toast } = useToast();

  const loadValidations = async () => {
    try {
      setIsLoading(true);
      const from = (currentPage - 1) * PAGE_SIZE;
      const to = from + PAGE_SIZE - 1;

      // Get validation records with user emails
      const query = supabase
        .from('validation_history')
        .select('*, user:user_id(email)')
        .order('created_at', { ascending: false });

      // Add search filter if search term exists
      if (search) {
        query.ilike('file_name', `%${search}%`);
      }

      const { data, error: validationError, count } = await query
        .range(from, to)
        .returns<ValidationWithUser[]>();

      if (validationError) {
        console.error('Error fetching validations:', validationError);
        throw validationError;
      }

      if (!data) {
        setValidations([]);
        setTotalPages(0);
        return;
      }

      // Transform data
      const transformedData: ValidationRecord[] = data.map((record: ValidationWithUser) => ({
        ...record,
        user_email: record.user?.email || 'Unknown'
      }));

      setValidations(transformedData);
      setTotalPages(Math.ceil((count || 0) / PAGE_SIZE));
    } catch (error) {
      console.error('Error:', error);
      toast({
        title: 'Error',
        description: 'Failed to load validation history',
        variant: 'destructive',
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadValidations();
  }, [currentPage, search]);

  const renderStatus = (status: string) => {
    switch (status) {
      case 'completed':
        return <Badge variant="default" className="bg-green-100 text-green-800">Completed</Badge>;
      case 'failed':
        return <Badge variant="destructive">Failed</Badge>;
      case 'processing':
        return <Badge variant="secondary">Processing</Badge>;
      default:
        return <Badge variant="secondary">{status}</Badge>;
    }
  };

  const handleDownload = (validation: ValidationRecord) => {
    try {
      const timestamp = format(new Date(validation.created_at), 'yyyy-MM-dd-HHmm');
      const validContent = validation.valid_emails.join('\n');
      const invalidContent = validation.invalid_emails.join('\n');
      const riskyContent = validation.risky_emails.join('\n');

      // Create a combined report
      const reportContent = `Validation Report - ${validation.file_name}\n` +
        `Date: ${format(new Date(validation.created_at), 'PPpp')}\n` +
        `User: ${validation.user_email}\n` +
        `Status: ${validation.status}\n` +
        `Response Time: ${validation.response_time}ms\n` +
        `Confidence: ${(validation.confidence * 100).toFixed(1)}%\n` +
        `Credits Used: ${validation.credits_used}\n\n` +
        `Valid Emails (${validation.valid_emails.length}):\n${validContent}\n\n` +
        `Invalid Emails (${validation.invalid_emails.length}):\n${invalidContent}\n\n` +
        `Risky Emails (${validation.risky_emails.length}):\n${riskyContent}`;

      const blob = new Blob([reportContent], { type: 'text/plain' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `validation-report-${timestamp}.txt`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);

      toast({
        title: 'Success',
        description: 'Report downloaded successfully',
      });
    } catch (error) {
      console.error('Download error:', error);
      toast({
        title: 'Error',
        description: 'Failed to download report',
        variant: 'destructive',
      });
    }
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle>Admin Validation History</CardTitle>
        <CardDescription>View all user validation history</CardDescription>
      </CardHeader>
      <CardContent>
        <div className="mb-4">
          <Input
            placeholder="Search by file name..."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            className="max-w-sm"
          />
        </div>

        {isLoading ? (
          <div className="flex justify-center py-8">
            <Loader2 className="h-8 w-8 animate-spin" />
          </div>
        ) : (
          <>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Date</TableHead>
                  <TableHead>User</TableHead>
                  <TableHead>File</TableHead>
                  <TableHead>Total</TableHead>
                  <TableHead>Results</TableHead>
                  <TableHead>Status</TableHead>
                  <TableHead>Actions</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {validations.map((validation) => (
                  <TableRow key={validation.id}>
                    <TableCell>
                      {format(new Date(validation.created_at), 'PPp')}
                    </TableCell>
                    <TableCell>{validation.user_email}</TableCell>
                    <TableCell>{validation.file_name}</TableCell>
                    <TableCell>{validation.total_emails}</TableCell>
                    <TableCell>
                      <div className="flex gap-2">
                        <Badge variant="secondary" className="bg-green-100 text-green-800">
                          {validation.valid_emails.length} valid
                        </Badge>
                        <Badge variant="secondary" className="bg-red-100 text-red-800">
                          {validation.invalid_emails.length} invalid
                        </Badge>
                        <Badge variant="secondary" className="bg-yellow-100 text-yellow-800">
                          {validation.risky_emails.length} risky
                        </Badge>
                      </div>
                    </TableCell>
                    <TableCell>{renderStatus(validation.status)}</TableCell>
                    <TableCell>
                      <Button
                        variant="outline"
                        size="sm"
                        onClick={() => handleDownload(validation)}
                      >
                        <Download className="h-4 w-4 mr-1" />
                        Download
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>

            {validations.length === 0 && (
              <div className="text-center py-8 text-muted-foreground">
                No validations found
              </div>
            )}

            {totalPages > 1 && (
              <div className="flex justify-between items-center mt-4">
                <Button
                  variant="outline"
                  size="sm"
                  onClick={() => setCurrentPage(p => Math.max(1, p - 1))}
                  disabled={currentPage === 1}
                >
                  Previous
                </Button>
                <div className="text-sm text-muted-foreground">
                  Page {currentPage} of {totalPages}
                </div>
                <Button
                  variant="outline"
                  size="sm"
                  onClick={() => setCurrentPage(p => p + 1)}
                  disabled={currentPage === totalPages}
                >
                  Next
                </Button>
              </div>
            )}
          </>
        )}
      </CardContent>
    </Card>
  );
}