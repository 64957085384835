import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardDescription,
  CardFooter,
} from '@/components/ui/card';
import { Badge } from '@/components/ui/badge';
import { Textarea } from '@/components/ui/textarea';
import { Separator } from '@/components/ui/separator';
import { useToast } from '@/hooks/use-toast';
import {
  getTicketDetails,
  addTicketResponse,
  subscribeToTicketResponses,
} from '@/lib/support';
import { SupportTicket, TicketResponse } from '@/lib/types/support';
import { formatDistanceToNow } from 'date-fns';
import { useAuth } from '@/hooks/use-auth';

export function TicketDetails() {
  const { ticketId } = useParams<{ ticketId: string }>();
  const navigate = useNavigate();
  const { toast } = useToast();
  const { user } = useAuth();
  const [ticket, setTicket] = useState<SupportTicket | null>(null);
  const [responses, setResponses] = useState<TicketResponse[]>([]);
  const [newResponse, setNewResponse] = useState('');
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (ticketId) {
      loadTicketDetails();
      const subscription = subscribeToTicketResponses(ticketId, (response) => {
        setResponses((prev) => [...prev, response]);
      });

      return () => {
        subscription.unsubscribe();
      };
    }
  }, [ticketId]);

  const loadTicketDetails = async () => {
    try {
      const { ticket, responses } = await getTicketDetails(ticketId!);
      setTicket(ticket);
      setResponses(responses);
    } catch (error) {
      console.error('Failed to load ticket details:', error);
      toast({
        title: 'Error',
        description: 'Failed to load ticket details',
        variant: 'destructive',
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSubmitResponse = async () => {
    if (!newResponse.trim()) return;

    try {
      setSubmitting(true);
      await addTicketResponse({
        ticket_id: ticketId!,
        message: newResponse,
      });
      setNewResponse('');
      toast({
        title: 'Response Added',
        description: 'Your response has been added successfully.',
      });
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to add response',
        variant: 'destructive',
      });
    } finally {
      setSubmitting(false);
    }
  };

  if (loading) {
    return <div className="flex justify-center py-8">Loading ticket details...</div>;
  }

  if (!ticket) {
    return <div className="text-center py-8">Ticket not found</div>;
  }

  return (
    <div className="space-y-6">
      <Card>
        <CardHeader>
          <div className="flex justify-between items-start">
            <div>
              <CardTitle>{ticket.subject}</CardTitle>
              <CardDescription>
                Created {formatDistanceToNow(new Date(ticket.created_at), { addSuffix: true })}
              </CardDescription>
            </div>
            <Button variant="outline" onClick={() => navigate('/support/tickets')}>
              Back to Tickets
            </Button>
          </div>
        </CardHeader>
        <CardContent>
          <div className="space-y-4">
            <div className="flex gap-2">
              <Badge variant="secondary">{ticket.status}</Badge>
              <Badge variant="secondary">{ticket.priority}</Badge>
              {ticket.category && <Badge variant="secondary">{ticket.category}</Badge>}
            </div>
            <div className="prose max-w-none">
              <p>{ticket.description}</p>
            </div>
          </div>
        </CardContent>
      </Card>

      <Card>
        <CardHeader>
          <CardTitle>Responses</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="space-y-6">
            {responses.map((response) => (
              <div
                key={response.id}
                className={`p-4 rounded-lg ${
                  response.is_admin_response ? 'bg-blue-50' : 'bg-gray-50'
                }`}
              >
                <div className="flex justify-between items-start mb-2">
                  <div className="font-medium">
                    {response.is_admin_response ? 'Support Team' : 'You'}
                  </div>
                  <div className="text-sm text-muted-foreground">
                    {formatDistanceToNow(new Date(response.created_at), {
                      addSuffix: true,
                    })}
                  </div>
                </div>
                <p className="text-sm">{response.message}</p>
              </div>
            ))}

            {responses.length === 0 && (
              <div className="text-center py-4 text-muted-foreground">
                No responses yet
              </div>
            )}
          </div>
        </CardContent>
        <CardFooter>
          <div className="w-full space-y-4">
            <Textarea
              placeholder="Write your response..."
              value={newResponse}
              onChange={(e) => setNewResponse(e.target.value)}
              className="min-h-[100px]"
            />
            <div className="flex justify-end">
              <Button
                onClick={handleSubmitResponse}
                disabled={submitting || !newResponse.trim()}
              >
                {submitting ? 'Sending...' : 'Send Response'}
              </Button>
            </div>
          </div>
        </CardFooter>
      </Card>
    </div>
  );
}
