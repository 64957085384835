import { useNavigate, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Button } from '@/components/ui/button';
import { ThemeToggle } from '@/components/ui/theme-toggle';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  DropdownMenuLabel,
  DropdownMenuSeparator,
} from '@/components/ui/dropdown-menu';
import { Bell, CreditCard, Menu, Settings, LogOut } from 'lucide-react';
import { getUserCredits } from '@/lib/api';
import { useTheme } from '@/components/theme-provider';
import { logoutUser } from '@/lib/auth';
import { Avatar, AvatarFallback } from "@/components/ui/avatar";
import { useToast } from "@/components/ui/use-toast";
import { getCurrentUser } from '@/lib/auth';

export function Header({ onMenuClick }: { onMenuClick: () => void }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [credits, setCredits] = useState<number>(0);
  const { theme, setTheme } = useTheme();
  const { toast } = useToast();
  const [userEmail, setUserEmail] = useState<string>('');

  const isAuthPage = location.pathname === '/login' || location.pathname === '/register';

  useEffect(() => {
    loadUserData();
    loadCredits();
    
    // Handle credit updates through custom event
    const handleCreditUpdate = (e: CustomEvent<{ credits: number }>) => {
      setCredits(e.detail.credits);
    };

    window.addEventListener('creditUpdate', handleCreditUpdate as EventListener);
    
    return () => {
      window.removeEventListener('creditUpdate', handleCreditUpdate as EventListener);
    };
  }, []);

  const loadUserData = async () => {
    const user = await getCurrentUser();
    if (user?.email) {
      setUserEmail(user.email);
    }
  };

  const loadCredits = async () => {
    try {
      const userCredits = await getUserCredits();
      setCredits(userCredits);
    } catch (error) {
      console.error('Failed to load credits:', error);
    }
  };

  const handleLogout = async () => {
    try {
      await logoutUser();
      toast({
        title: "Logged out successfully",
        description: "You have been logged out of your account.",
      });
    } catch (error) {
      console.error('Logout failed:', error);
      toast({
        title: "Logout failed",
        description: "There was a problem logging out. Please try again.",
        variant: "destructive",
      });
    }
  };

  return (
    <header className="sticky top-0 z-50 w-full border-b bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60">
      <div className="flex h-16 w-full items-center justify-between px-4 md:px-6 2xl:px-8">
        <div className="flex items-center">
          <button
            className="mr-1 inline-flex h-9 w-9 items-center justify-center rounded-md hover:bg-accent hover:text-accent-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 md:hidden"
            onClick={onMenuClick}
          >
            <Menu className="h-5 w-5" />
            <span className="sr-only">Toggle menu</span>
          </button>

          <div className="flex items-center">
            {isAuthPage ? (
              <img
                src={theme === 'light' 
                  ? "/img/logo-light.svg"
                  : "/img/logo-dark.svg"
                }
                alt="EmailCleaner"
                className="h-12 max-w-none"
              />
            ) : (
              <>
                <img
                  src={theme === 'light' 
                    ? "/img/logo-light.svg"
                    : "/img/logo-dark.svg"
                  }
                  alt="EmailCleaner"
                  className="hidden h-14 max-w-none md:block"
                />
                <img
                  src={theme === 'light' 
                    ? "/img/mobile_light.svg"
                    : "/img/mobile_dark.svg"
                  }
                  alt="EmailCleaner"
                  className="h-10 max-w-none md:hidden"
                />
              </>
            )}
          </div>
        </div>

        <div className="flex items-center gap-2 sm:gap-3 md:gap-4">
          <Button
            variant="ghost"
            size="sm"
            className={`flex items-center gap-1.5 rounded-full px-2.5 py-1.5 transition-all duration-200 sm:gap-2 sm:px-3 md:px-4 ${
              theme === 'dark' 
                ? 'bg-emerald-500/30 hover:bg-emerald-500/40' 
                : 'bg-emerald-500/10 hover:bg-emerald-500/20'
            }`}
            onClick={() => navigate('/credits')}
          >
            <CreditCard className={`h-3.5 w-3.5 sm:h-4 sm:w-4 ${theme === 'dark' ? 'text-white' : 'text-emerald-600'}`} />
            <span className={`hidden text-xs sm:text-sm md:inline ${theme === 'dark' ? 'text-white' : 'text-emerald-700'}`}>Credits:</span>
            <span className={`text-xs font-medium sm:text-sm ${theme === 'dark' ? 'text-white' : 'text-emerald-700'}`}>{credits}</span>
          </Button>

          <div className="flex items-center gap-2 sm:gap-3">
            <ThemeToggle />

            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button 
                  variant="ghost" 
                  size="icon"
                  className="h-8 w-8 rounded-full sm:h-9 sm:w-9"
                >
                  <Avatar className="h-7 w-7 sm:h-8 sm:w-8">
                    <AvatarFallback className="bg-primary/10 text-primary text-xs sm:text-sm">
                      {userEmail?.charAt(0).toUpperCase()}
                    </AvatarFallback>
                  </Avatar>
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end" className="w-56">
                <DropdownMenuLabel className="font-normal">
                  <div className="flex flex-col space-y-1">
                    <p className="text-sm font-medium leading-none">{userEmail}</p>
                    <Button
                      variant="ghost"
                      size="sm"
                      className="mt-1 -ml-2 h-8 justify-start px-2 text-left"
                      onClick={() => {
                        navigate('/credits');
                      }}
                    >
                      <CreditCard className="mr-2 h-4 w-4" />
                      <span className="text-xs">{credits} credits available</span>
                    </Button>
                  </div>
                </DropdownMenuLabel>
                <DropdownMenuSeparator />
                <DropdownMenuItem 
                  onClick={() => navigate('/settings')}
                  className="cursor-pointer"
                >
                  <Settings className="mr-2 h-4 w-4" />
                  Settings
                </DropdownMenuItem>
                <DropdownMenuItem 
                  onClick={handleLogout}
                  className="cursor-pointer text-red-500 focus:text-red-500"
                >
                  <LogOut className="mr-2 h-4 w-4" />
                  Logout
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        </div>
      </div>
    </header>
  );
}