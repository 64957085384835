import { supabase } from './supabase';
import type { Database } from './database.types';
import {
  SupportTicket,
  TicketResponse,
  TicketNotification,
  CreateTicketData,
  UpdateTicketData,
  CreateResponseData,
} from './types/support';

// Create a new support ticket
export async function createTicket(data: CreateTicketData): Promise<SupportTicket> {
  const user = await supabase.auth.getUser();
  if (!user.data.user?.id) {
    throw new Error('User not authenticated');
  }

  const { data: ticket, error } = await supabase
    .from('support_tickets')
    .insert({
      ...data,
      user_id: user.data.user.id,
    })
    .select('*')
    .single();

  if (error) throw error;
  return ticket as SupportTicket;
}

// Get user's tickets
export async function getUserTickets(): Promise<SupportTicket[]> {
  const user = await supabase.auth.getUser();
  if (!user.data.user?.id) {
    throw new Error('User not authenticated');
  }

  const { data: tickets, error } = await supabase
    .from('support_tickets')
    .select('*')
    .eq('user_id', user.data.user.id)
    .order('created_at', { ascending: false });

  if (error) throw error;
  return tickets as SupportTicket[];
}

// Get a specific ticket with its responses
export async function getTicketDetails(ticketId: string): Promise<{
  ticket: SupportTicket;
  responses: TicketResponse[];
}> {
  const { data: ticket, error: ticketError } = await supabase
    .from('support_tickets')
    .select('*')
    .eq('id', ticketId)
    .single();

  if (ticketError) throw ticketError;

  const { data: responses, error: responsesError } = await supabase
    .from('ticket_responses')
    .select('*')
    .eq('ticket_id', ticketId)
    .order('created_at', { ascending: true });

  if (responsesError) throw responsesError;

  return { 
    ticket: ticket as SupportTicket, 
    responses: responses as TicketResponse[] 
  };
}

// Update a ticket
export async function updateTicket(
  ticketId: string,
  data: UpdateTicketData
): Promise<SupportTicket> {
  const { data: ticket, error } = await supabase
    .from('support_tickets')
    .update(data)
    .eq('id', ticketId)
    .select('*')
    .single();

  if (error) throw error;
  return ticket as SupportTicket;
}

// Add a response to a ticket
export async function addTicketResponse(data: CreateResponseData): Promise<TicketResponse> {
  const user = await supabase.auth.getUser();
  if (!user.data.user?.id) {
    throw new Error('User not authenticated');
  }

  const { data: response, error } = await supabase
    .from('ticket_responses')
    .insert({
      ...data,
      user_id: user.data.user.id,
    })
    .select('*')
    .single();

  if (error) throw error;

  // Create notification for the ticket owner
  const { data: ticket } = await supabase
    .from('support_tickets')
    .select('user_id')
    .eq('id', data.ticket_id)
    .single();

  if (ticket && ticket.user_id) {
    await supabase.from('ticket_notifications').insert({
      user_id: ticket.user_id,
      ticket_id: data.ticket_id,
      message: `New response on your ticket`,
      is_read: false,
    });
  }

  return response as TicketResponse;
}

// Get user's notifications
export async function getUserNotifications(): Promise<TicketNotification[]> {
  const user = await supabase.auth.getUser();
  if (!user.data.user?.id) {
    throw new Error('User not authenticated');
  }

  const { data: notifications, error } = await supabase
    .from('ticket_notifications')
    .select('*')
    .eq('user_id', user.data.user.id)
    .order('created_at', { ascending: false });

  if (error) throw error;
  return notifications as TicketNotification[];
}

// Mark notification as read
export async function markNotificationAsRead(notificationId: string): Promise<void> {
  const { error } = await supabase
    .from('ticket_notifications')
    .update({ is_read: true })
    .eq('id', notificationId);

  if (error) throw error;
}

// Admin: Get all tickets
export async function getAllTickets(): Promise<SupportTicket[]> {
  const user = await supabase.auth.getUser();
  if (!user.data.user?.id) {
    throw new Error('User not authenticated');
  }

  // Check if user is admin
  const { data: profile, error: profileError } = await supabase
    .from('user_profiles')
    .select('is_admin')
    .eq('id', user.data.user.id)
    .single();

  if (profileError) throw profileError;
  if (!profile?.is_admin) {
    throw new Error('Unauthorized: Admin access required');
  }

  // First get all tickets
  const { data: tickets, error: ticketsError } = await supabase
    .from('support_tickets')
    .select('*')
    .order('created_at', { ascending: false });

  if (ticketsError) throw ticketsError;

  // Then get user profiles for all ticket creators
  const userIds = [...new Set(tickets.map(ticket => ticket.user_id))];
  const { data: profiles, error: profilesError } = await supabase
    .from('user_profiles')
    .select('id, email')
    .in('id', userIds);

  if (profilesError) throw profilesError;

  // Create a map of user_id to email
  const emailMap = new Map(profiles?.map(profile => [profile.id, profile.email]));

  // Transform tickets with user emails
  const transformedTickets = tickets.map(ticket => ({
    ...ticket,
    profiles: {
      email: emailMap.get(ticket.user_id) || 'Unknown'
    }
  }));

  return transformedTickets as SupportTicket[];
}

// Subscribe to new responses for a ticket
export function subscribeToTicketResponses(
  ticketId: string,
  callback: (response: TicketResponse) => void
) {
  return supabase
    .channel(`ticket-responses-${ticketId}`)
    .on(
      'postgres_changes',
      {
        event: 'INSERT',
        schema: 'public',
        table: 'ticket_responses',
        filter: `ticket_id=eq.${ticketId}`,
      },
      (payload) => callback(payload.new as TicketResponse)
    )
    .subscribe();
}

// Subscribe to user's notifications
export function subscribeToNotifications(
  userId: string,
  callback: (notification: TicketNotification) => void
) {
  return supabase
    .channel(`user-notifications-${userId}`)
    .on(
      'postgres_changes',
      {
        event: 'INSERT',
        schema: 'public',
        table: 'ticket_notifications',
        filter: `user_id=eq.${userId}`,
      },
      (payload) => callback(payload.new as TicketNotification)
    )
    .subscribe();
}
