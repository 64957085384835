import { useState, useEffect } from 'react';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Switch } from '@/components/ui/switch';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import { useToast } from '@/components/ui/use-toast';
import { getCurrentUser } from '@/lib/auth';
import { supabase } from '@/lib/supabase';
import { Loader2, Upload, AlertTriangle } from 'lucide-react';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { useNavigate } from 'react-router-dom';
import { Form, FormField } from "@/components/ui/form";
import { useForm } from "react-hook-form";
import { User } from '@/lib/types';

interface UserProfile {
  id: string;
  email: string;
  name: string;
  emailVerified: boolean;
  avatar_url: string | null;
  emailNotifications: boolean;
  weeklyReports: boolean;
  marketingEmails: boolean;
  securityAlerts: boolean;
  company_name: string | null;
  billing_address: string | null;
  city: string | null;
  state: string | null;
  postal_code: string | null;
  country: string | null;
  vat_number: string | null;
  tax_id: string | null;
}

interface PasswordFormValues {
  new: string;
  confirm: string;
}

export function SettingsForm() {
  const { toast } = useToast();
  const navigate = useNavigate();
  const [showPasswordDialog, setShowPasswordDialog] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isSavingUser, setIsSavingUser] = useState(false);
  const [isSavingBilling, setIsSavingBilling] = useState(false);
  const [profile, setProfile] = useState<UserProfile>({
    id: '',
    email: '',
    name: '',
    emailVerified: false,
    avatar_url: null,
    emailNotifications: false,
    weeklyReports: false,
    marketingEmails: false,
    securityAlerts: false,
    company_name: null,
    billing_address: null,
    city: null,
    state: null,
    postal_code: null,
    country: null,
    vat_number: null,
    tax_id: null
  });

  const form = useForm<UserProfile>({
    defaultValues: profile
  });

  const passwordForm = useForm<PasswordFormValues>();

  useEffect(() => {
    loadUserProfile();
  }, []);

  useEffect(() => {
    form.reset(profile);
  }, [profile]);

  const loadUserProfile = async () => {
    try {
      const user = await getCurrentUser();
      
      if (!user) throw new Error('No user found');

      setProfile({
        id: user.id,
        email: user.email,
        name: user.name ?? '',
        emailVerified: user.emailVerified,
        avatar_url: user.avatar_url ?? null,
        emailNotifications: user.email_notifications ?? false,
        weeklyReports: user.weekly_reports ?? false,
        marketingEmails: false,
        securityAlerts: false,
        company_name: user.company_name ?? null,
        billing_address: user.billing_address ?? null,
        city: user.city ?? null,
        state: user.state ?? null,
        postal_code: user.postal_code ?? null,
        country: user.country ?? null,
        vat_number: user.vat_number ?? null,
        tax_id: user.tax_id ?? null
      });

      setIsLoading(false);
    } catch (error) {
      console.error('Error loading user profile:', error);
      toast({
        title: 'Error',
        description: 'Failed to load user profile',
        variant: 'destructive',
      });
      navigate('/');
    }
  };

  const handleAvatarUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const file = event.target.files?.[0];
      if (!file) {
        toast({
          title: 'No File Selected',
          description: 'Please select an image file to upload.',
          variant: 'destructive',
        });
        return;
      }

      const { data: { user } } = await supabase.auth.getUser();
      if (!user) {
        toast({
          title: 'Not Authenticated',
          description: 'Please log in again to update your avatar.',
          variant: 'destructive',
        });
        return;
      }

      // Upload image to storage
      const fileExt = file.name.split('.').pop();
      const fileName = `${user.id}-${Math.random()}.${fileExt}`;
      const { error: uploadError, data } = await supabase.storage
        .from('avatars')
        .upload(fileName, file);

      if (uploadError) throw uploadError;

      // Get public URL
      const { data: { publicUrl } } = supabase.storage
        .from('avatars')
        .getPublicUrl(fileName);

      // Update profile with new avatar URL
      const { error: updateError } = await supabase
        .from('user_profiles')
        .update({
          avatar_url: publicUrl,
          updated_at: new Date().toISOString()
        })
        .eq('id', user.id);

      if (updateError) throw updateError;

      setProfile({ ...profile, avatar_url: publicUrl });
      toast({
        title: 'Avatar Updated',
        description: 'Your profile picture has been updated successfully.',
      });
    } catch (error) {
      console.error('Error uploading avatar:', error);
      toast({
        title: 'Upload Failed',
        description: error instanceof Error 
          ? error.message 
          : 'Could not upload your profile picture. Please try again.',
        variant: 'destructive',
      });
    }
  };

  const handleChangePassword = async (data: PasswordFormValues) => {
    if (!data.new || !data.confirm) {
      toast({
        title: 'Missing Information',
        description: 'Please fill in both password fields to continue.',
        variant: 'destructive',
      });
      return;
    }

    if (data.new.length < 8) {
      toast({
        title: 'Invalid Password',
        description: 'Your password must be at least 8 characters long.',
        variant: 'destructive',
      });
      return;
    }

    if (data.new !== data.confirm) {
      toast({
        title: 'Password Mismatch',
        description: 'The passwords you entered do not match. Please try again.',
        variant: 'destructive',
      });
      return;
    }

    setIsSavingUser(true);
    try {
      const { error } = await supabase.auth.updateUser({
        password: data.new,
      });

      if (error) throw error;

      toast({
        title: 'Password Updated',
        description: 'Your password has been changed successfully. Please use your new password next time you log in.',
      });
      
      passwordForm.reset();
    } catch (error: any) {
      console.error('Error changing password:', error);
      toast({
        title: 'Update Failed',
        description: error?.message || 'Could not change your password. Please try again.',
        variant: 'destructive',
      });
    } finally {
      setIsSavingUser(false);
    }
  };

  const handleAccountDeletion = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) {
        toast({
          title: 'Not Authenticated',
          description: 'Please log in again to delete your account.',
          variant: 'destructive',
        });
        return;
      }

      // Delete user data from profiles
      const { error: profileError } = await supabase
        .from('user_profiles')
        .delete()
        .eq('id', user.id);

      if (profileError) throw profileError;

      // Delete auth user
      const { error: authError } = await supabase.auth.admin.deleteUser(user.id);
      if (authError) throw authError;

      toast({
        title: 'Account Deleted',
        description: 'Your account and all associated data have been permanently deleted.',
      });
      navigate('/login');
    } catch (error) {
      console.error('Error deleting account:', error);
      toast({
        title: 'Deletion Failed',
        description: error instanceof Error 
          ? error.message 
          : 'Could not delete your account. Please contact support if this persists.',
        variant: 'destructive',
      });
    }
  };

  const handleSaveUserSettings = async (data: UserProfile) => {
    if (!profile.id) {
      toast({
        title: 'Profile Not Found',
        description: 'Could not find your profile. Please try logging in again.',
        variant: 'destructive',
      });
      return;
    }

    setIsSavingUser(true);
    try {
      const { error } = await supabase
        .from('user_profiles')
        .update({
          email_notifications: data.emailNotifications,
          weekly_reports: data.weeklyReports,
          marketing_emails: data.marketingEmails,
          security_alerts: data.securityAlerts,
          updated_at: new Date().toISOString()
        })
        .eq('id', profile.id);

      if (error) throw error;

      toast({
        title: 'Settings Saved',
        description: 'Your notification preferences have been updated successfully.',
      });
      
      await loadUserProfile();
    } catch (error: any) {
      console.error('Error saving user settings:', error);
      toast({
        title: 'Save Failed',
        description: error?.message || 'Could not save your notification preferences. Please try again.',
        variant: 'destructive',
      });
    } finally {
      setIsSavingUser(false);
    }
  };

  const handleSaveBillingSettings = async (data: UserProfile) => {
    if (!profile.id) {
      toast({
        title: 'Profile Not Found',
        description: 'Could not find your profile. Please try logging in again.',
        variant: 'destructive',
      });
      return;
    }

    setIsSavingBilling(true);
    try {
      const { error } = await supabase
        .from('user_profiles')
        .update({
          company_name: data.company_name || null,
          billing_address: data.billing_address || null,
          city: data.city || null,
          state: data.state || null,
          postal_code: data.postal_code || null,
          country: data.country || null,
          vat_number: data.vat_number || null,
          tax_id: data.tax_id || null,
          updated_at: new Date().toISOString()
        })
        .eq('id', profile.id);

      if (error) throw error;

      toast({
        title: 'Billing Info Updated',
        description: 'Your billing information has been saved successfully.',
      });

      setProfile(prev => ({
        ...prev,
        ...data
      }));
    } catch (error: any) {
      console.error('Error saving billing settings:', error);
      toast({
        title: 'Save Failed',
        description: error?.message || 'Could not save your billing information. Please try again.',
        variant: 'destructive',
      });
      form.reset(profile);
    } finally {
      setIsSavingBilling(false);
    }
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-[400px]">
        <Loader2 className="h-8 w-8 animate-spin" />
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <Form {...form}>
        <form onSubmit={form.handleSubmit(handleSaveUserSettings)} className="space-y-6">
          <Card>
            <CardHeader>
              <CardTitle>Profile Settings</CardTitle>
              <CardDescription>
                Update your personal information and profile picture
              </CardDescription>
            </CardHeader>
            <CardContent>
              <div className="flex items-center space-x-4">
                <Avatar className="h-20 w-20">
                  <AvatarImage src={profile.avatar_url || undefined} />
                  <AvatarFallback>{profile.name.charAt(0)}</AvatarFallback>
                </Avatar>
                <div>
                  <input
                    type="file"
                    id="avatar"
                    className="hidden"
                    accept="image/*"
                    onChange={handleAvatarUpload}
                  />
                  <Label htmlFor="avatar">
                    <Button variant="outline" asChild>
                      <span>
                        <Upload className="mr-2 h-4 w-4" />
                        Change Avatar
                      </span>
                    </Button>
                  </Label>
                </div>
              </div>

              {!profile.emailVerified && (
                <div className="flex items-center space-x-2 text-yellow-600 bg-yellow-50 p-2 rounded">
                  <AlertTriangle className="h-4 w-4" />
                  <span className="text-sm">Please verify your email address</span>
                  <Button
                    variant="link"
                    className="text-yellow-600"
                    onClick={async () => {
                      const { error } = await supabase.auth.resend({
                        type: 'signup',
                        email: profile.email,
                      });
                      if (!error) {
                        toast({
                          title: 'Verification Email Sent',
                          description: 'Please check your inbox',
                        });
                      }
                    }}
                  >
                    Resend verification email
                  </Button>
                </div>
              )}

              <div className="flex justify-end">
                <Button 
                  type="submit"
                  disabled={isSavingUser}
                >
                  {isSavingUser ? "Saving..." : "Save User Settings"}
                </Button>
              </div>
            </CardContent>
          </Card>
        </form>

        <form onSubmit={form.handleSubmit(handleSaveBillingSettings)} className="space-y-6">
          <Card>
            <CardHeader>
              <CardTitle>Billing Information</CardTitle>
              <CardDescription>
                Add your billing details for invoice generation
              </CardDescription>
            </CardHeader>
            <CardContent className="space-y-4">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div className="space-y-2">
                  <Label htmlFor="company_name">Company Name</Label>
                  <Input
                    id="company_name"
                    {...form.register('company_name')}
                    placeholder="Your company name"
                  />
                </div>
                <div className="space-y-2">
                  <Label htmlFor="vat_number">VAT Number</Label>
                  <Input
                    id="vat_number"
                    {...form.register('vat_number')}
                    placeholder="VAT number (if applicable)"
                  />
                </div>
                <div className="space-y-2">
                  <Label htmlFor="tax_id">Tax ID</Label>
                  <Input
                    id="tax_id"
                    {...form.register('tax_id')}
                    placeholder="Tax ID or EIN"
                  />
                </div>
                <div className="space-y-2">
                  <Label htmlFor="billing_address">Billing Address</Label>
                  <Input
                    id="billing_address"
                    {...form.register('billing_address')}
                    placeholder="Street address"
                  />
                </div>
                <div className="space-y-2">
                  <Label htmlFor="city">City</Label>
                  <Input
                    id="city"
                    {...form.register('city')}
                    placeholder="City"
                  />
                </div>
                <div className="space-y-2">
                  <Label htmlFor="state">State/Province</Label>
                  <Input
                    id="state"
                    {...form.register('state')}
                    placeholder="State or province"
                  />
                </div>
                <div className="space-y-2">
                  <Label htmlFor="postal_code">Postal Code</Label>
                  <Input
                    id="postal_code"
                    {...form.register('postal_code')}
                    placeholder="Postal or ZIP code"
                  />
                </div>
                <div className="space-y-2">
                  <Label htmlFor="country">Country</Label>
                  <Input
                    id="country"
                    {...form.register('country')}
                    placeholder="Country"
                  />
                </div>
              </div>
              <div className="flex justify-end">
                <Button 
                  type="submit"
                  disabled={isSavingBilling}
                >
                  {isSavingBilling ? "Saving..." : "Save Billing Information"}
                </Button>
              </div>
            </CardContent>
          </Card>
        </form>

        <Card>
          <CardHeader>
            <CardTitle>Account Settings</CardTitle>
            <CardDescription>Manage your account settings</CardDescription>
          </CardHeader>
          <CardContent>
            <form onSubmit={passwordForm.handleSubmit(handleChangePassword)}>
              <div className="mt-6">
                <Label>Change Password</Label>
                <Input
                  type="password"
                  placeholder="New Password"
                  {...passwordForm.register('new', { 
                    required: true,
                    minLength: {
                      value: 8,
                      message: "Password must be at least 8 characters"
                    }
                  })}
                  className="mb-4"
                />
                <Input
                  type="password"
                  placeholder="Confirm New Password"
                  {...passwordForm.register('confirm', { 
                    required: true,
                    validate: (value) => 
                      value === passwordForm.getValues('new') || "Passwords do not match"
                  })}
                  className="mb-4"
                />
                <Button type="submit" disabled={isSavingUser} className="w-full">
                  {isSavingUser ? <Loader2 className="mr-2 h-4 w-4 animate-spin" /> : 'Change Password'}
                </Button>
              </div>
            </form>
          </CardContent>
        </Card>

        <Card>
          <CardHeader>
            <CardTitle>Notification Preferences</CardTitle>
            <CardDescription>Configure how you receive notifications</CardDescription>
          </CardHeader>
          <CardContent className="space-y-6">
            <div className="flex items-center justify-between">
              <div className="space-y-0.5">
                <Label>Email Notifications</Label>
                <p className="text-sm text-muted-foreground">
                  Receive notifications about completed validations
                </p>
              </div>
              <Switch
                checked={profile.emailNotifications}
                onCheckedChange={(checked) =>
                  setProfile({ ...profile, emailNotifications: checked })
                }
              />
            </div>
            <div className="flex items-center justify-between">
              <div className="space-y-0.5">
                <Label>Weekly Reports</Label>
                <p className="text-sm text-muted-foreground">
                  Get weekly summaries of your validation activities
                </p>
              </div>
              <Switch
                checked={profile.weeklyReports}
                onCheckedChange={(checked) =>
                  setProfile({ ...profile, weeklyReports: checked })
                }
              />
            </div>
            <div className="flex items-center justify-between">
              <div className="space-y-0.5">
                <Label>Marketing Emails</Label>
                <p className="text-sm text-muted-foreground">
                  Receive updates about new features and promotions
                </p>
              </div>
              <Switch
                checked={profile.marketingEmails}
                onCheckedChange={(checked) =>
                  setProfile({ ...profile, marketingEmails: checked })
                }
              />
            </div>
            <div className="flex items-center justify-between">
              <div className="space-y-0.5">
                <Label>Security Alerts</Label>
                <p className="text-sm text-muted-foreground">
                  Get notified about important security updates
                </p>
              </div>
              <Switch
                checked={profile.securityAlerts}
                onCheckedChange={(checked) =>
                  setProfile({ ...profile, securityAlerts: checked })
                }
              />
            </div>
          </CardContent>
        </Card>

        <Card className="border-destructive">
          <CardHeader>
            <CardTitle className="text-destructive">Danger Zone</CardTitle>
            <CardDescription>
              Permanently delete your account and all associated data
            </CardDescription>
          </CardHeader>
          <CardContent>
            <Button
              variant="destructive"
              onClick={() => setShowDeleteDialog(true)}
            >
              Delete Account
            </Button>
          </CardContent>
        </Card>
      </Form>

      {/* Password Change Dialog */}
      <Dialog open={showPasswordDialog} onOpenChange={setShowPasswordDialog}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Change Password</DialogTitle>
            <DialogDescription>
              Enter your new password
            </DialogDescription>
          </DialogHeader>
          <div className="space-y-4">
            <div className="space-y-2">
              <Label htmlFor="new-password">New Password</Label>
              <Input
                id="new-password"
                type="password"
                value={passwordForm.getValues('new')}
                onChange={(e) =>
                  passwordForm.setValue('new', e.target.value)
                }
              />
            </div>
            <div className="space-y-2">
              <Label htmlFor="confirm-password">Confirm New Password</Label>
              <Input
                id="confirm-password"
                type="password"
                value={passwordForm.getValues('confirm')}
                onChange={(e) =>
                  passwordForm.setValue('confirm', e.target.value)
                }
              />
            </div>
          </div>
          <DialogFooter>
            <Button variant="outline" onClick={() => setShowPasswordDialog(false)}>
              Cancel
            </Button>
            <Button onClick={passwordForm.handleSubmit(handleChangePassword)}>Update Password</Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      {/* Delete Account Dialog */}
      <Dialog open={showDeleteDialog} onOpenChange={setShowDeleteDialog}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Delete Account</DialogTitle>
            <DialogDescription>
              This action cannot be undone. This will permanently delete your
              account and remove your data from our servers.
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <Button variant="outline" onClick={() => setShowDeleteDialog(false)}>
              Cancel
            </Button>
            <Button variant="destructive" onClick={handleAccountDeletion}>
              Delete Account
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
}