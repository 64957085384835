import { useState, useEffect } from 'react';
import { Button } from '@/components/ui/button';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { useToast } from '@/components/ui/use-toast';
import { Loader2, Send } from 'lucide-react';
import type { EmailMarketingIntegration, EmailList, EmailMarketingPlatform } from '@/types/email-marketing';
import { supabase } from '@/lib/supabase';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import { cn } from '@/lib/utils';

interface ValidationResults {
  valid: string[];
  invalid: string[];
  risky: string[];
  disposable: string[];
  errors: Record<string, string>;
}

interface ValidationData {
  id: string;
  results: ValidationResults;
  total_emails: number;
  valid_count: number;
  invalid_count: number;
  risky_count: number;
}

interface ExportEmailsProps {
  validationId: string;
  totalEmails: number;
  validCount: number;
  variant?: 'ghost' | 'outline';
  className?: string;
}

export function ExportEmails({ validationId, totalEmails, validCount, variant = 'ghost', className }: ExportEmailsProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [integrations, setIntegrations] = useState<EmailMarketingIntegration[]>([]);
  const [selectedPlatform, setSelectedPlatform] = useState('');
  const [lists, setLists] = useState<EmailList[]>([]);
  const [selectedList, setSelectedList] = useState('');
  const { toast } = useToast();

  console.log('ExportEmails - Props:', { validationId, totalEmails, validCount });

  useEffect(() => {
    if (isOpen) {
      loadIntegrations();
    }
  }, [isOpen]);

  const loadIntegrations = async () => {
    try {
      // Get current user
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('No authenticated user');

      console.log('Fetching integrations for user:', user.id);

      const { data, error } = await supabase
        .from('email_marketing_integrations')
        .select('*')
        .eq('user_id', user.id)
        .eq('is_active', true);

      if (error) {
        console.error('Database error:', error);
        throw error;
      }

      console.log('Found integrations:', data);
      
      // Convert database types to component types
      const convertedData: EmailMarketingIntegration[] = (data || []).map(item => ({
        id: item.id,
        user_id: item.user_id,
        platform: item.platform as EmailMarketingPlatform,
        api_key: item.api_key || undefined,
        oauth_token: item.oauth_token || undefined,
        oauth_refresh_token: item.oauth_refresh_token || undefined,
        oauth_expires_at: item.oauth_expires_at || undefined,
        status: item.is_active ? 'connected' : 'disconnected',
        created_at: item.created_at,
        updated_at: item.updated_at,
        last_sync_at: undefined,
        settings: item.settings ? {
          ...(item.settings as Record<string, any>),
          listId: (item.settings as any)?.listId,
          apiUrl: (item.settings as any)?.apiUrl
        } : { listId: undefined, apiUrl: undefined }
      }));

      setIntegrations(convertedData);
    } catch (error) {
      console.error('Error loading integrations:', error);
      toast({
        title: 'Error',
        description: error instanceof Error ? error.message : 'Failed to load integrations',
        variant: 'destructive',
      });
    }
  };

  const loadLists = async (platformId: string) => {
    try {
      const integration = integrations.find(i => i.id === platformId);
      if (!integration) {
        throw new Error('Integration not found');
      }

      if (integration.platform === 'brevo') {
        const response = await fetch('https://api.brevo.com/v3/contacts/lists', {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'api-key': integration.api_key || '',
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch lists from Brevo');
        }

        const data = await response.json();
        const convertedLists: EmailList[] = data.lists.map((list: any) => ({
          id: list.id.toString(),
          name: list.name,
          subscriber_count: list.totalSubscribers,
          external_id: list.id.toString()
        }));

        console.log('Brevo lists:', convertedLists);
        setLists(convertedLists);
      } else if (integration.platform === 'mailchimp') {
        // Use our API endpoint instead of calling Mailchimp directly
        const response = await fetch(`${import.meta.env.VITE_API_URL}/mailchimp/lists`, {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          credentials: 'include',
        });

        if (!response.ok) {
          const errorData = await response.json();
          console.error('Mailchimp API error:', errorData);
          throw new Error(errorData.error || 'Failed to fetch lists from Mailchimp');
        }

        const data = await response.json();
        const convertedLists: EmailList[] = data.lists.map((list: any) => ({
          id: list.id,
          name: list.name,
          subscriber_count: list.stats.member_count,
          external_id: list.id
        }));

        console.log('Mailchimp lists:', convertedLists);
        setLists(convertedLists);
      }
    } catch (error) {
      console.error('Error loading lists:', error);
      toast({
        title: 'Error',
        description: error instanceof Error ? error.message : 'Failed to load lists',
        variant: 'destructive',
      });
    }
  };

  const handlePlatformChange = (value: string) => {
    setSelectedPlatform(value);
    setSelectedList('');
    if (value) {
      loadLists(value);
    }
  };

  const handleExport = async () => {
    if (!selectedPlatform || !selectedList) {
      toast({
        title: 'Error',
        description: 'Please select a platform and list',
        variant: 'destructive',
      });
      return;
    }

    if (!validationId) {
      toast({
        title: 'Error',
        description: 'No validation ID provided',
        variant: 'destructive',
      });
      return;
    }

    try {
      setLoading(true);

      // Get the selected integration
      const integration = integrations.find(i => i.id === selectedPlatform);
      if (!integration) {
        throw new Error('Selected integration not found');
      }

      console.log('Fetching validation results for ID:', validationId);

      // Get the validation results from Supabase
      const { data: validationData, error: validationError } = await supabase
        .from('email_validations')
        .select('id, valid_emails, total_emails')
        .eq('id', validationId)
        .single();

      if (validationError) {
        console.error('Validation error:', validationError);
        throw new Error('Failed to fetch validation results');
      }
      
      if (!validationData || !validationData.valid_emails) {
        throw new Error('No validation results found');
      }

      // Type guard to check if valid_emails is a string array
      const isValidEmails = (obj: unknown): obj is string[] => {
        return Array.isArray(obj) && obj.every(item => typeof item === 'string');
      };

      if (!isValidEmails(validationData.valid_emails)) {
        throw new Error('Invalid validation results format');
      }

      const validEmails = validationData.valid_emails;

      // Process in batches of 100
      const batchSize = 100;
      const batches = [];
      for (let i = 0; i < validEmails.length; i += batchSize) {
        batches.push(validEmails.slice(i, i + batchSize));
      }

      if (integration.platform === 'brevo') {
        for (const batch of batches) {
          const response = await fetch('https://api.brevo.com/v3/contacts/import', {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'api-key': integration.api_key || '',
            },
            body: JSON.stringify({
              listIds: [parseInt(selectedList)],
              jsonBody: batch.map((email: string) => ({
                email: email,
                emailBlacklisted: false,
                smsBlacklisted: false,
                updateEnabled: true
              }))
            }),
          });

          if (!response.ok) {
            const errorData = await response.json();
            throw new Error(`Failed to export to Brevo: ${errorData.message || response.statusText}`);
          }

          const result = await response.json();
          console.log('Brevo import result:', result);
        }

        toast({
          title: 'Success',
          description: `Successfully exported ${validEmails.length} emails to Brevo`,
        });
      } else if (integration.platform === 'mailchimp') {
        for (const batch of batches) {
          const response = await fetch(`${import.meta.env.VITE_API_URL}/mailchimp/add-subscribers`, {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify({
              listId: selectedList,
              emails: batch
            }),
          });

          if (!response.ok) {
            const errorData = await response.json();
            console.error('Mailchimp API error:', errorData);
            throw new Error(errorData.error || 'Failed to export to Mailchimp');
          }

          const result = await response.json();
          console.log('Mailchimp batch operation result:', result);
        }

        toast({
          title: 'Success',
          description: `Successfully started batch import of ${validEmails.length} emails to Mailchimp`,
        });
      }
    } catch (error) {
      console.error('Error exporting emails:', error);
      toast({
        title: 'Error',
        description: error instanceof Error ? error.message : 'Failed to export emails',
        variant: 'destructive',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        <Button
          variant={variant}
          size="sm"
          className={cn(
            'flex items-center gap-2',
            variant === 'ghost' ? 'h-8 w-8' : 'min-w-[140px] h-9',
            className
          )}
          disabled={loading}
        >
          {loading ? (
            <Loader2 className="h-4 w-4 animate-spin" />
          ) : (
            <>
              <Send className={cn(
                "h-4 w-4",
                variant === 'ghost' && "h-4 w-4"
              )} />
              {variant === 'outline' && 'Export to List'}
              {variant === 'ghost' && <span className="sr-only">Export to List</span>}
            </>
          )}
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Export Emails</DialogTitle>
          <DialogDescription>
            Export your validated emails to your email marketing platform
          </DialogDescription>
        </DialogHeader>

        <div className="space-y-4 py-4">
          {integrations.length === 0 ? (
            <div className="space-y-4">
              <p className="text-sm text-muted-foreground">
                No email marketing platforms connected. Please connect a platform in the Email Marketing section.
              </p>
              <Button
                variant="outline"
                className="w-full"
                onClick={() => window.location.href = '/email-marketing'}
              >
                Go to Email Marketing
              </Button>
            </div>
          ) : (
            <>
              <div className="space-y-2">
                <label className="text-sm font-medium">Select Platform</label>
                <Select value={selectedPlatform} onValueChange={handlePlatformChange}>
                  <SelectTrigger>
                    <SelectValue placeholder="Select a platform" />
                  </SelectTrigger>
                  <SelectContent>
                    {integrations.map((integration) => (
                      <SelectItem key={integration.id} value={integration.id}>
                        {integration.platform.charAt(0).toUpperCase() + integration.platform.slice(1)}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>

              {selectedPlatform && (
                <div className="space-y-2">
                  <label className="text-sm font-medium">Select List</label>
                  <Select value={selectedList} onValueChange={setSelectedList}>
                    <SelectTrigger>
                      <SelectValue placeholder="Select a list" />
                    </SelectTrigger>
                    <SelectContent>
                      {lists.map((list) => (
                        <SelectItem key={list.id} value={list.id}>
                          {list.name} ({list.subscriber_count} subscribers)
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>
              )}

              <Button
                className="w-full"
                onClick={() => {
                  console.log('Export button clicked with:', {
                    validationId,
                    selectedPlatform,
                    selectedList,
                    loading
                  });
                  handleExport();
                }}
                disabled={loading || !selectedPlatform || !selectedList}
              >
                {loading && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
                Export {validCount} Emails
              </Button>
            </>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
}
