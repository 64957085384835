import { supabase } from './supabase';
import type { ValidationResult, ValidationHistoryRecord, RateLimit, APIProvider, ProviderName, ValidationIssues, EmailProviders, ProviderConfig, APIValidationResult, APIResponse, EdgeFunctionValidationResult } from '@/types/providers';
import { validateEmail as internalValidateEmail } from './emailValidator';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { providerSettings } from './providerSettings';

export class ValidationService {
  private static instance: ValidationService;
  private rateLimitCache: Map<string, RateLimit> = new Map();
  private constructor() {}

  public static getInstance(): ValidationService {
    if (!ValidationService.instance) {
      ValidationService.instance = new ValidationService();
    }
    return ValidationService.instance;
  }

  async getNextProvider(): Promise<ProviderConfig | null> {
    try {
      const providers = await providerSettings.getProviders();
      if (!providers) {
        console.error('No providers configuration found');
        return null;
      }

      // Convert providers object to array and filter enabled ones
      const enabledProviders = Object.values(providers).filter(p => p.enabled && p.endpoint);

      if (enabledProviders.length === 0) {
        console.error('No enabled providers found with valid endpoint');
        return null;
      }

      // Sort by priority (lower number = higher priority)
      enabledProviders.sort((a, b) => a.priority - b.priority);

      // Return the highest priority provider
      return enabledProviders[0];
    } catch (error) {
      console.error('Error getting next provider:', error);
      return null;
    }
  }

  async validateEmails(
    emails: string | string[],
    userId: string,
    ipAddress: string,
    onProgress?: (processed: number, total: number) => void,
    fileName?: string
  ): Promise<EdgeFunctionValidationResult[]> {
    try {
      // Ensure emails is always an array and not empty
      const emailArray = Array.isArray(emails) ? emails : [emails];
      
      if (emailArray.length === 0 || !emailArray[0]) {
        throw new Error('No email provided');
      }

      // Check if user is authenticated
      const { data: sessionData } = await supabase.auth.getSession();
      const session = sessionData?.session;
      
      if (!session) {
        console.error('No active session found');
        throw new Error('No authenticated user');
      }

      // Get provider config
      const provider = await this.getNextProvider();
      if (!provider) {
        console.error('No provider available');
        throw new Error('No email validation provider available');
      }

      // Get user's current credits
      const { data: userProfile, error: userError } = await supabase
        .from('user_profiles')
        .select('credits')
        .eq('id', userId)
        .single();

      if (userError) {
        console.error('Error getting user credits:', userError);
        throw new Error('Could not verify user credits');
      }

      if (!userProfile || userProfile.credits < emailArray.length) {
        throw new Error('Insufficient credits');
      }

      console.log('Calling Edge Function with:', {
        emails: emailArray,
        userId,
        providerType: provider.providerType,
        fileName: fileName || (emailArray.length === 1 ? emailArray[0] : `Validation ${new Date().toISOString()}`)
      });

      // Call Edge Function
      const response = await fetch(`${import.meta.env.VITE_SUPABASE_URL}/functions/v1/validate-email`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${session.access_token}`
        },
        body: JSON.stringify({ 
          emails: emailArray,
          userId,
          providerType: provider.providerType,
          fileName: fileName || (emailArray.length === 1 ? emailArray[0] : `Validation ${new Date().toISOString()}`)
        }),
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error('Edge Function error:', response.status, errorText);
        throw new Error(`API error: ${response.statusText}`);
      }

      const data = await response.json();
      console.log('Edge Function response:', data);

      // Ensure we have results
      if (!data.results || !Array.isArray(data.results)) {
        throw new Error('Invalid response format from validation service');
      }

      return data.results as EdgeFunctionValidationResult[];

    } catch (error) {
      console.error('Error validating emails:', error);
      throw error;
    }
  }

  async validateWithInternalSystem(email: string, userId: string): Promise<ValidationResult> {
    const startTime = Date.now();
    const internalResult = await internalValidateEmail(email);
    const response_time = Date.now() - startTime;
    const validationId = uuidv4();

    const validationResult: ValidationResult = {
      id: validationId,
      date: new Date(),
      status: 'completed',
      totalEmails: 1,
      creditsUsed: 0,
      valid: internalResult.isValid ? [email] : [],
      invalid: !internalResult.isValid ? [email] : [],
      risky: [],
      disposable: [],
      spamTraps: [],
      confidence: internalResult.isValid ? 0.9 : 0.1,
      issues: internalResult.isValid ? undefined : {
        message: internalResult.reason,
        code: 'INTERNAL_VALIDATION'
      },
      provider: 'internal',
      response_time,
      validation_result: JSON.stringify(internalResult)
    };

    // Record validation
    await this.recordValidation(email, validationResult, userId, 'internal', response_time);

    return validationResult;
  }

  async recordValidation(
    email: string,
    result: ValidationResult,
    userId: string,
    providerId?: string,
    response_time?: number,
    error?: string
  ): Promise<void> {
    try {
      // Prepare validation result in the correct format
      const validationResult = {
        valid: result.valid?.includes(email) || false,
        greylisted: result.risky?.includes(email) || false,
        error: error || null,
        response_time: response_time || null,
        details: result // Store full result for reference
      };

      // First try the RPC function
      const { data: rpcData, error: rpcError } = await supabase
        .rpc('record_validation_with_credits', {
          p_user_id: userId,
          p_email: email,
          p_validation_result: validationResult,
          p_provider: providerId || 'generic',
          p_credits_used: 1
        });

      if (!rpcError) {
        return; // Success!
      }

      console.error('RPC error:', rpcError);

      // Fall back to direct table insert with transaction
      const { data: validationData, error: insertError } = await supabase
        .from('email_validations')
        .insert({
          user_id: userId,
          email: email,
          validation_result: validationResult,
          provider: providerId || 'generic',
          status: 'completed',
          total_emails: 1,
          valid_emails: validationResult.valid ? [email] : [],
          invalid_emails: !validationResult.valid && !validationResult.greylisted ? [email] : [],
          risky_emails: validationResult.greylisted ? [email] : [],
          credits_used: 1
        })
        .select('id')
        .single();

      if (insertError) {
        console.error('Error inserting validation:', insertError);
        throw insertError;
      }

      if (!validationData?.id) {
        throw new Error('No validation ID returned after insert');
      }

      // Get current credit balance
      const { data: balanceData } = await supabase
        .from('credit_transactions')
        .select('balance')
        .order('created_at', { ascending: false })
        .limit(1)
        .single();

      const currentBalance = balanceData?.balance || 0;

      // Record credit transaction
      const { error: creditError } = await supabase
        .from('credit_transactions')
        .insert({
          user_id: userId,
          validation_id: validationData.id,
          type: 'validation',
          amount: -1,
          balance: currentBalance - 1,
          description: `Email validation: ${email}`
        });

      if (creditError) {
        // If credit transaction fails, delete the validation record to maintain consistency
        await supabase
          .from('email_validations')
          .delete()
          .match({ id: validationData.id });

        console.error('Error recording credit usage:', creditError);
        throw creditError;
      }
    } catch (error) {
      console.error('Error recording validation:', error);
      throw error;
    }
  }

  async getValidationStats(userId: string, startDate?: string, endDate?: string): Promise<any> {
    try {
      const { data, error } = await supabase.rpc('get_validation_stats', {
        p_user_id: userId,
        p_start_date: startDate || new Date(Date.now() - 30 * 24 * 60 * 60 * 1000).toISOString(),
        p_end_date: endDate || new Date().toISOString()
      });

      if (error) {
        console.error('Stats error:', error);
        throw error;
      }

      return data;
    } catch (error) {
      console.error('Get stats error:', error);
      throw error;
    }
  }

  async getValidationHistory(userId: string, startDate?: string, endDate?: string): Promise<any> {
    try {
      const { data: stats, error: statsError } = await supabase.rpc('get_validation_stats', {
        p_user_id: userId,
        p_start_date: startDate || new Date(Date.now() - 30 * 24 * 60 * 60 * 1000).toISOString(),
        p_end_date: endDate || new Date().toISOString()
      });

      if (statsError) {
        console.error('Stats error:', statsError);
        throw statsError;
      }

      const { data: history, error: historyError } = await supabase
        .from('validation_history')
        .select('*')
        .eq('user_id', userId)
        .order('validation_date', { ascending: false })
        .limit(100);

      if (historyError) {
        console.error('History error:', historyError);
        throw historyError;
      }

      return {
        stats,
        history: history || []
      };
    } catch (error) {
      console.error('Get history error:', error);
      throw error;
    }
  }

  clearCache(): void {
    this.rateLimitCache.clear();
  }
}

export async function recordValidation(
  userId: string,
  email: string,
  providerId: string,
  providerType: string,
  isValid: boolean,
  confidence: number,
  error?: string,
  rawResponse?: any
): Promise<any> {
  try {
    const { data, error: dbError } = await supabase
      .from('validation_history')
      .insert({
        user_id: userId,
        email: email,
        provider_id: providerId,
        provider_type: providerType,
        is_valid: isValid,
        status: 'completed',
        confidence: confidence,
        credits_used: 1,
        error: error,
        raw_response: rawResponse
      })
      .select()
      .single();

    if (dbError) {
      console.error(' Error recording validation:', dbError);
      throw dbError;
    }

    return data;
  } catch (error) {
    console.error(' Error recording validation:', error);
    throw error;
  }
}

export const validationService = ValidationService.getInstance();
