import { supabase } from './supabase'
import { User } from './types'

interface UserProfileBasic {
  id: string;
  credits: number;
  name: string | null;
  avatar_url: string | null;
  email_notifications: boolean;
  weekly_reports: boolean;
}

interface UserProfileBilling {
  company_name: string | null;
  billing_address: string | null;
  city: string | null;
  state: string | null;
  postal_code: string | null;
  country: string | null;
  vat_number: string | null;
  tax_id: string | null;
}

export async function loginUser(email: string, password: string) {
  try {
    const { data: authData, error: authError } = await supabase.auth.signInWithPassword({
      email,
      password
    })

    if (authError) {
      console.error('Auth error:', authError)
      if (authError.message.includes('Invalid login credentials')) {
        throw new Error('Invalid email or password')
      }
      throw new Error(authError.message)
    }

    if (!authData.user) {
      throw new Error('No user data returned')
    }

    // Fetch user's credit balance after successful login
    const { data: userData, error: userError } = await supabase
      .from('user_profiles')
      .select('credits')
      .eq('id', authData.user.id)
      .single()

    if (userError) {
      console.error('Profile fetch error:', userError)
      throw new Error('Error fetching user profile')
    }

    if (userData === null) {
      throw new Error('User profile not found')
    }

    return {
      user: {
        id: authData.user.id,
        email: authData.user.email,
        credits: userData.credits,
      },
      session: authData.session
    }
  } catch (error) {
    console.error('Login error:', error)
    throw error
  }
}

export async function registerUser(email: string, password: string, name: string) {
  try {
    console.log('Starting registration process for:', email);

    // First, check if user exists
    const { data: existingUser } = await supabase
      .from('user_profiles')
      .select('id')
      .eq('email', email)
      .single();

    if (existingUser) {
      throw new Error('An account with this email already exists');
    }

    // Send OTP for email verification
    const { error: otpError } = await supabase.auth.signInWithOtp({
      email,
      options: {
        shouldCreateUser: true,
        data: {
          name,
          password, // Store password temporarily
        }
      }
    });

    if (otpError) {
      console.error('OTP send error:', otpError);
      if (otpError.message.includes('rate limit')) {
        throw new Error('Too many verification attempts. Please wait a few minutes before trying again.');
      }
      throw new Error('Failed to send verification code. Please try again later.');
    }

    return { 
      email,
      message: 'Please check your email for verification instructions.'
    };

  } catch (error) {
    console.error('Registration error:', error);
    if (error instanceof Error) {
      throw error;
    }
    throw new Error('An unexpected error occurred during registration');
  }
}

export async function verifyOTP(email: string, token: string, password: string, name: string) {
  try {
    // Verify OTP
    const { data, error } = await supabase.auth.verifyOtp({
      email,
      token,
      type: 'signup'
    });

    if (error) {
      console.error('OTP verification error:', error);
      throw error;
    }

    if (!data.user) {
      throw new Error('Verification failed - no user returned');
    }

    // After successful verification, set the user's password
    const { error: updateError } = await supabase.auth.updateUser({
      password: password,
      data: { name }
    });

    if (updateError) {
      console.error('User update error:', updateError);
      throw new Error('Failed to complete registration');
    }

    // Create user profile with initial credits and name
    const { error: profileError } = await supabase
      .from('user_profiles')
      .insert({
        id: data.user.id,
        email: email,
        name: name,
        credits: 300,  
        created_at: new Date().toISOString(),
        email_verified: true
      });

    if (profileError) {
      console.error('Profile creation error:', profileError);
      // Don't throw here, as the user is already created
    }

    return {
      user: data.user,
      session: data.session
    };
  } catch (error) {
    console.error('OTP verification error:', error);
    throw error;
  }
}

export async function resendOTP(email: string) {
  try {
    const { error } = await supabase.auth.signInWithOtp({
      email,
      options: {
        shouldCreateUser: false // Don't create new user when resending
      }
    });

    if (error) {
      console.error('Resend OTP error:', error);
      if (error.message.includes('rate limit')) {
        throw new Error('Please wait a few minutes before requesting another code.');
      }
      throw error;
    }
  } catch (error) {
    console.error('Resend OTP error:', error);
    throw error;
  }
}

export async function logoutUser() {
  try {
    // Clear all local storage items first
    localStorage.removeItem('admin-session-expiry');
    localStorage.removeItem('admin-auth');
    localStorage.removeItem('sb-lcsmfoxaoeljigdopgpf-auth-token');
    localStorage.removeItem('theme-preference'); // Clear theme preference
    
    // Reset theme to light mode
    document.documentElement.classList.remove('dark');
    
    // Sign out from Supabase
    const { error } = await supabase.auth.signOut();
    if (error) throw error;
    
    // Force reload to clear any remaining state and redirect
    window.location.href = '/login';
    
    return true;
  } catch (error) {
    console.error('Logout error:', error);
    throw new Error('Failed to logout');
  }
}

export async function getCurrentUser(): Promise<User | null> {
  try {
    const { data: { user } } = await supabase.auth.getUser()
    
    if (!user) return null

    // Fetch user profile with basic fields first
    const { data: basicProfile, error: basicError } = await supabase
      .from('user_profiles')
      .select('id, credits, name, avatar_url, email_notifications, weekly_reports')
      .eq('id', user.id)
      .single() as { 
        data: UserProfileBasic | null; 
        error: any 
      }

    if (basicError) {
      console.error('Error fetching basic profile:', basicError)
      return null
    }

    // Create the user object with basic fields
    const userProfile: User = {
      id: user.id,
      email: user.email ?? '',
      credits: basicProfile?.credits ?? 0,
      name: basicProfile?.name ?? '',
      emailVerified: user.email_confirmed_at ? true : false,
      avatar_url: basicProfile?.avatar_url,
      email_notifications: basicProfile?.email_notifications ?? false,
      weekly_reports: basicProfile?.weekly_reports ?? false,
      company_name: null,
      billing_address: null,
      city: null,
      state: null,
      postal_code: null,
      country: null,
      vat_number: null,
      tax_id: null
    }

    // Try to fetch billing fields
    try {
      const { data: billingProfile } = await supabase
        .from('user_profiles')
        .select(`
          company_name,
          billing_address,
          city,
          state,
          postal_code,
          country,
          vat_number,
          tax_id
        `)
        .eq('id', user.id)
        .single() as {
          data: UserProfileBilling | null;
          error: any
        }

      if (billingProfile) {
        userProfile.company_name = billingProfile.company_name
        userProfile.billing_address = billingProfile.billing_address
        userProfile.city = billingProfile.city
        userProfile.state = billingProfile.state
        userProfile.postal_code = billingProfile.postal_code
        userProfile.country = billingProfile.country
        userProfile.vat_number = billingProfile.vat_number
        userProfile.tax_id = billingProfile.tax_id
      }
    } catch (error) {
      // If billing fields query fails, continue with basic profile
      console.error('Error fetching billing info:', error)
    }

    return userProfile
  } catch (error) {
    console.error('Get current user error:', error)
    return null
  }
}

export async function checkAuth() {
  try {
    const { data: { session }, error } = await supabase.auth.getSession()
    if (error) throw error
    return !!session
  } catch (error) {
    console.error('Auth check error:', error)
    return false
  }
}

export async function signInWithGoogle() {
  const { data, error } = await supabase.auth.signInWithOAuth({
    provider: 'google',
    options: {
      redirectTo: `${window.location.origin}/auth/callback`
    }
  })

  if (error) throw error
  return data
}

export async function resetPassword(email: string) {
  try {
    const { error } = await supabase.auth.resetPasswordForEmail(email, {
      redirectTo: `${window.location.origin}/reset-password`,
    })

    if (error) {
      throw error
    }

    return true
  } catch (error) {
    console.error('Reset password error:', error)
    throw error
  }
}