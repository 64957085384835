import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import { useToast } from '@/components/ui/use-toast';
import { supabase } from '@/lib/supabase';
import { Loader2 } from 'lucide-react';
import { useAppContext } from '@/contexts/AppContext';

const MAX_RETRIES = 3;
const RETRY_DELAY = 1000; // 1 second

export function AdminLoginForm() {
  const navigate = useNavigate();
  const { toast } = useToast();
  const { adminAuth } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      // Sign in attempt
      const { data: authData, error: authError } = await supabase.auth.signInWithPassword({
        email: email.trim().toLowerCase(),
        password,
      });

      if (authError) throw authError;
      if (!authData.user || !authData.session) {
        throw new Error('No user data returned');
      }

      // Verify admin status
      const { data: adminUser, error: adminError } = await supabase
        .from('user_profiles')
        .select('is_admin')
        .eq('id', authData.user.id)
        .single();

      if (adminError) throw adminError;
      if (!adminUser?.is_admin) {
        throw new Error('Insufficient privileges');
      }

      // Set admin session
      adminAuth.login(authData.session);

      toast({
        title: 'Login successful',
        description: 'Welcome to the admin dashboard',
      });

      // Add a small delay to ensure the admin state is set
      setTimeout(() => {
        navigate('/admin', { replace: true });
      }, 100);
    } catch (error) {
      console.error('Login error:', error);
      toast({
        variant: 'destructive',
        title: 'Login failed',
        description: error instanceof Error 
          ? error.message 
          : 'An error occurred during login',
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Card className="w-[350px]">
      <CardHeader>
        <CardTitle>Admin Login</CardTitle>
        <CardDescription>
          Sign in to access the admin dashboard
        </CardDescription>
      </CardHeader>
      <CardContent>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="space-y-2">
            <Label htmlFor="email">Email</Label>
            <Input
              id="email"
              type="email"
              placeholder="admin@example.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="space-y-2">
            <Label htmlFor="password">Password</Label>
            <Input
              id="password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <Button type="submit" className="w-full" disabled={isLoading}>
            {isLoading ? (
              <>
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                Signing in...
              </>
            ) : (
              'Sign in'
            )}
          </Button>
        </form>
      </CardContent>
    </Card>
  );
}