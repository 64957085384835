import { useEffect, useState } from 'react'
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import { Label } from '@/components/ui/label'
import { Input } from '@/components/ui/input'
import { Switch } from '@/components/ui/switch'
import { useToast } from '@/components/ui/use-toast'
import { supabase } from '@/lib/supabase'
import { EmailProviders, ProviderConfig, ProviderName } from '@/types/providers'
import { Button } from '@/components/ui/button'
import { Loader2 } from 'lucide-react'
import { Json } from '@/lib/database.types'

export default function ProviderSettings() {
  const [providers, setProviders] = useState<EmailProviders>({})
  const [loading, setLoading] = useState(true)
  const [saving, setSaving] = useState(false)
  const [hasChanges, setHasChanges] = useState(false)
  const { toast } = useToast()

  useEffect(() => {
    const checkAuthAndLoadSettings = async () => {
      const { data: { session }, error: authError } = await supabase.auth.getSession()
      
      if (authError) {
        console.error('Auth error:', authError)
        toast({
          title: 'Authentication Error',
          description: 'Please sign in to access provider settings',
          variant: 'destructive'
        })
        return
      }

      if (!session) {
        toast({
          title: 'Not Authenticated',
          description: 'Please sign in to access provider settings',
          variant: 'destructive'
        })
        return
      }

      loadProviderSettings()
    }

    checkAuthAndLoadSettings()
  }, [])

  function convertToJson(providers: EmailProviders): Json {
    return Object.entries(providers).reduce((acc, [key, value]) => ({
      ...acc,
      [key]: {
        apiKey: value.apiKey,
        enabled: value.enabled,
        creditsUsed: value.creditsUsed,
        creditsRemaining: value.creditsRemaining,
        endpoint: value.endpoint,
        host: value.host,
        dailyLimit: value.dailyLimit
      }
    }), {}) as Json
  }

  async function loadProviderSettings() {
    try {
      const { data, error } = await supabase
        .from('admin_settings')
        .select('*')
        .eq('key', 'email_providers')
        .maybeSingle();

      if (error) throw error;

      const defaultSettings: EmailProviders = {
        gmail: { 
          apiKey: '', 
          enabled: false, 
          creditsUsed: 0, 
          creditsRemaining: 1000000,
          endpoint: 'https://gmail.emailbounce.org/v1',
          host: 'gmail.emailbounce.org',
          dailyLimit: 50000
        },
        microsoft: { 
          apiKey: '', 
          enabled: false, 
          creditsUsed: 0, 
          creditsRemaining: 500000,
          endpoint: 'https://microsoft.emailbounce.org/v1',
          host: 'microsoft.emailbounce.org',
          dailyLimit: 25000
        },
        yahoo: { 
          apiKey: '', 
          enabled: false, 
          creditsUsed: 0, 
          creditsRemaining: 200000,
          endpoint: 'https://yahoo.emailbounce.org/v1',
          host: 'yahoo.emailbounce.org',
          dailyLimit: 10000
        },
        others: { 
          apiKey: '', 
          enabled: false, 
          creditsUsed: 0, 
          creditsRemaining: 100000,
          endpoint: 'https://api.emailbounce.org/v1',
          host: 'api.emailbounce.org',
          dailyLimit: 5000
        }
      };

      let providerData: EmailProviders = { ...defaultSettings };
      
      if (data?.value && typeof data.value === 'object') {
        const existingData = data.value as EmailProviders;
        
        // Only keep valid provider data and merge with defaults
        Object.keys(defaultSettings).forEach(provider => {
          if (existingData[provider] && isProviderConfig(existingData[provider])) {
            providerData[provider] = {
              ...defaultSettings[provider],
              ...existingData[provider],
              // Ensure dailyLimit exists
              dailyLimit: existingData[provider].dailyLimit || defaultSettings[provider].dailyLimit
            };
          }
        });
      } else {
        // Initialize with default settings if none exist
        const { error: insertError } = await supabase
          .from('admin_settings')
          .insert({
            key: 'email_providers',
            value: convertToJson(defaultSettings)
          });

        if (insertError) {
          console.error('Error inserting default settings:', insertError);
        }
      }

      setProviders(providerData);
      setHasChanges(false);
    } catch (error) {
      console.error('Error loading provider settings:', error);
      toast({
        title: 'Error',
        description: 'Failed to load provider settings',
        variant: 'destructive'
      });
    } finally {
      setLoading(false);
    }
  }

  async function saveChanges() {
    try {
      setSaving(true);

      // Call our RPC function instead of direct table access
      const { data, error } = await supabase.rpc('update_provider_settings', {
        provider_key: 'email_providers',
        new_api_key: JSON.stringify(convertToJson(providers)),
        new_enabled: true
      });

      if (error) throw error;

      setHasChanges(false);
      toast({
        title: 'Success',
        description: 'Provider settings saved successfully',
        variant: 'default'
      });
    } catch (error) {
      console.error('Error saving provider settings:', error);
      toast({
        title: 'Error',
        description: error instanceof Error ? error.message : 'Failed to save provider settings',
        variant: 'destructive'
      });
    } finally {
      setSaving(false);
    }
  }

  function handleProviderUpdate(provider: ProviderName, field: keyof ProviderConfig, value: string | boolean | number) {
    const updatedProviders = {
      ...providers,
      [provider]: {
        ...providers[provider],
        [field]: value
      }
    };
    setProviders(updatedProviders);
    setHasChanges(true);
  }

  if (loading) {
    return (
      <div className="flex items-center justify-center p-8">
        <Loader2 className="h-8 w-8 animate-spin" />
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h2 className="text-2xl font-bold">Provider Settings</h2>
        <Button 
          onClick={saveChanges} 
          disabled={!hasChanges || saving}
        >
          {saving ? (
            <>
              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              Saving...
            </>
          ) : (
            'Save Changes'
          )}
        </Button>
      </div>

      <div className="grid gap-6 md:grid-cols-2">
        {Object.entries(providers).map(([provider, config]) => (
          <Card key={provider}>
            <CardHeader>
              <CardTitle className="capitalize">{provider}</CardTitle>
            </CardHeader>
            <CardContent className="space-y-4">
              <div className="space-y-2">
                <Label htmlFor={`${provider}-apiKey`}>API Key</Label>
                <Input
                  id={`${provider}-apiKey`}
                  type="password"
                  value={config.apiKey || ''}
                  onChange={(e) => handleProviderUpdate(provider as ProviderName, 'apiKey', e.target.value)}
                />
              </div>
              <div className="space-y-2">
                <Label htmlFor={`${provider}-endpoint`}>Endpoint</Label>
                <Input
                  id={`${provider}-endpoint`}
                  type="text"
                  value={config.endpoint || ''}
                  onChange={(e) => handleProviderUpdate(provider as ProviderName, 'endpoint', e.target.value)}
                />
              </div>
              <div className="space-y-2">
                <Label htmlFor={`${provider}-host`}>Host</Label>
                <Input
                  id={`${provider}-host`}
                  type="text"
                  value={config.host || ''}
                  onChange={(e) => handleProviderUpdate(provider as ProviderName, 'host', e.target.value)}
                />
              </div>
              <div className="space-y-2">
                <Label htmlFor={`${provider}-dailyLimit`}>Daily Limit</Label>
                <Input
                  id={`${provider}-dailyLimit`}
                  type="number"
                  value={config.dailyLimit}
                  onChange={(e) => handleProviderUpdate(provider as ProviderName, 'dailyLimit', parseInt(e.target.value))}
                />
              </div>
              <div className="flex items-center space-x-2">
                <Switch
                  id={`${provider}-enabled`}
                  checked={config.enabled}
                  onCheckedChange={(checked) => handleProviderUpdate(provider as ProviderName, 'enabled', checked)}
                />
                <Label htmlFor={`${provider}-enabled`}>Enabled</Label>
              </div>
              <div className="pt-2">
                <div className="text-sm text-muted-foreground">
                  Credits Used: {config.creditsUsed}
                </div>
                <div className="text-sm text-muted-foreground">
                  Credits Remaining: {config.creditsRemaining}
                </div>
                <div className="text-sm text-muted-foreground">
                  Daily Limit: {config.dailyLimit} validations
                </div>
              </div>
            </CardContent>
          </Card>
        ))}
      </div>
    </div>
  );
}

function isProviderConfig(value: unknown): value is ProviderConfig {
  if (typeof value !== 'object' || value === null) return false
  const config = value as Partial<ProviderConfig>
  return (
    typeof config.enabled === 'boolean' &&
    typeof config.creditsUsed === 'number' &&
    typeof config.creditsRemaining === 'number' &&
    typeof config.dailyLimit === 'number' &&
    (config.apiKey === undefined || typeof config.apiKey === 'string') &&
    (config.endpoint === undefined || typeof config.endpoint === 'string') &&
    (config.host === undefined || typeof config.host === 'string')
  )
}
