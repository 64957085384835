import { AlertTriangle } from 'lucide-react';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';

export function MaintenancePage() {
  return (
    <div className="flex items-center justify-center min-h-screen bg-background">
      <Card className="w-full max-w-lg mx-4">
        <CardHeader className="text-center">
          <div className="flex justify-center mb-4">
            <AlertTriangle className="h-12 w-12 text-yellow-500" />
          </div>
          <CardTitle className="text-2xl font-bold">Maintenance Mode</CardTitle>
        </CardHeader>
        <CardContent className="text-center">
          <p className="text-muted-foreground">
            We're currently performing maintenance to improve our services.
            Please check back later.
          </p>
          <p className="mt-4 text-sm text-muted-foreground">
            We apologize for any inconvenience. Our team is working to get everything back up as soon as possible.
          </p>
        </CardContent>
      </Card>
    </div>
  );
}
