import { supabase } from '@/lib/supabase';

export async function checkMaintenanceMode() {
  try {
    const { data, error } = await supabase
      .from('admin_settings')
      .select('value')
      .eq('key', 'maintenance_mode')
      .single();

    if (error) {
      if (error.code === 'PGRST116') {
        // No settings found, try to create default settings
        const { error: updateError } = await supabase
          .from('admin_settings')
          .update({ value: false })
          .eq('key', 'maintenance_mode');

        if (updateError) {
          // If update fails, try to insert
          const { error: insertError } = await supabase
            .from('admin_settings')
            .insert({
              key: 'maintenance_mode',
              value: false
            });

          if (insertError) throw insertError;
        }
        return false;
      }
      throw error;
    }

    return data?.value || false;
  } catch (error) {
    console.error('Error checking maintenance mode:', error);
    return false;
  }
}
