import { createContext, useContext } from 'react';
import { Session } from '@supabase/supabase-js';

interface UserProfile {
  created_at: string;
  completed_onboarding: boolean;
}

interface AppContextType {
  session: Session | null;
  isAdmin: boolean;
  isMaintenanceMode: boolean;
  setIsMaintenanceMode: (value: boolean) => void;
  adminAuth: {
    isAuthenticated: boolean;
    login: (session: Session) => void;
    logout: () => void;
  };
  userProfile: UserProfile | null;
}

export const AppContext = createContext<AppContextType>({
  session: null,
  isAdmin: false,
  isMaintenanceMode: false,
  setIsMaintenanceMode: () => {},
  adminAuth: {
    isAuthenticated: false,
    login: () => {},
    logout: () => {},
  },
  userProfile: null,
});

export const useAppContext = () => useContext(AppContext);
